import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";

import {
  Button,
  Checkbox,
  CheckboxProps,
  // Dimmer,
  Icon,
  Input,
  InputProps,
  // Loader,
  Modal,
} from "semantic-ui-react";

// #import { formatDate } from "react-lib/utils";
// import moment from "moment";
import htmlParse from "html-react-parser";

import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModMedReconcileAlert from "react-lib/apps/common/ModMedReconcileAlert";
import SnackMessage from "react-lib/apps/common/SnackMessage";
import CardLayout from "react-lib/apps/common/CardLayout";
import DateTextBox from "react-lib/apps/common/DateTextBox";

import CardNursingDiagnosisEditUX from "./CardNursingDiagnosisEditUX";
// #import CardNursingDiagnosisSearchUX from "./CardNursingDiagnosisSearchUX";
import CardNursingDiagnosisTemplateUX from "./CardNursingDiagnosisTemplateUX";
import CardNursingDiagnosisUX from "./CardNursingDiagnosisUX";
import { useIntl } from "react-intl";

import {
  ACTIONS,
  BTN_ACTS,
  CARD_NURSING_DIAGNOSIS,
  PickedProps,
  RunSequence,
  State,
} from "./sequence/NursingDiagnosis";

type CardNursingDiagnosisProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: () => any) => any;
  // seq
  runSequence: RunSequence;
  NursingDiagnosisSequence?: State["NursingDiagnosisSequence"];

  divisionType: string | null;
  medReconcileCheck?: any;
  medReconcileIndex?: number;
  isPHV?: boolean;
  encounterId?: any;
} & PickedProps;

const CardNursingDiagnosis = (props: CardNursingDiagnosisProps) => {
  const intl = useIntl();
  const [openEdit, setOpenEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  // #const [openModal, setOpenModal] = useState(false);
  // const [isCheckAll, setIsCheckAll] = useState(false);
  const [openModalTemplate, setOpenModalTemplate] = useState(false);
  const [checkBox, setCheckBox] = useState<number[]>([]);

  // Effect
  useEffect(() => {
    if (
      ![
        props.buttonLoadCheck?.[BTN_ACTS.SAVE_TEMPLATE],
        props.buttonLoadCheck?.[BTN_ACTS.SAVE],
        props.buttonLoadCheck?.[BTN_ACTS.UPDATE],
      ].includes("SUCCESS")
    ) {
      return;
    }

    setOpenCreate(false);
    setOpenEdit(false);
    setOpenModalTemplate(false);
  }, [props.buttonLoadCheck]);

  useEffect(
    () => () => {
      props.setProp("errorMessage.NursingDiagnosis", null);
      props.setProp("successMessage.NursingDiagnosis", null);
    },
    []
  );

  useEffect(() => {
    const results = (props.NursingDiagnosisSequence?.resultGroupList || []).flatMap(
      (item) => item.results
    );
    const resultIds = new Set(results.map((result) => result.id));

    setCheckBox((prevCheckbox) => prevCheckbox.filter((id) => resultIds.has(id)));
  }, [props.NursingDiagnosisSequence?.resultGroupList]);

  // #useEffect(() => {
  //   if (
  //     checkBox.length === props.NursingDiagnosisSequence?.nursingDiagnosisSelected?.results.length
  //   ) {
  //     setIsCheckAll(true);
  //   } else {
  //     setIsCheckAll(false);
  //   }
  // }, [checkBox]);

  useEffect(() => {
    props.runSequence({
      sequence: "NursingDiagnosis",
      action: ACTIONS.GET_DATA,
      card: CARD_NURSING_DIAGNOSIS,
      encounterId: props.encounterId,
    });

    setOpenEdit(false);
    setOpenCreate(false);
  }, []);

  useEffect(() => {
    props.runSequence({
      sequence: "NursingDiagnosis",
      action: ACTIONS.TEMPLATE_FORM,
      encounterId: props.encounterId,
    });
  }, [props.NursingDiagnosisSequence?.nursingDiagnosis?.templateID]);

  useEffect(() => {
    // #if (
    //   props.errorMessage?.NursingDiagnosis !== null &&
    //   props.errorMessage?.NursingDiagnosis !== undefined
    // ) {
    //   setTimeout(() => {
    //     props.runSequence({
    //       sequence: "NursingDiagnosis",
    //       action: "clear",
    //     });
    //   }, 2000);
    // }
    // #if (
    //   props.successMessage?.NursingDiagnosis !== null &&
    //   props.successMessage?.NursingDiagnosis !== undefined &&
    //   props.NursingDiagnosisSequence?.selectedEncouter === null
    // ) {
    //   props.runSequence({
    //     sequence: "NursingDiagnosis",
    //     action: "clear",
    //   });
    // }
  }, [props.errorMessage?.NursingDiagnosis, props.successMessage?.NursingDiagnosis]);

  // Memo callback
  const isCheckAll = useMemo(() => {
    const results = (props.NursingDiagnosisSequence?.resultGroupList || []).flatMap(
      (item) => item.results
    );

    return results.length > 0 && results.length === checkBox.length;
  }, [checkBox, props.NursingDiagnosisSequence?.resultGroupList]);

  // Callback
  const splitCreate = useCallback((created: string) => {
    const day = created.split("<");
    const time = created.split(">");

    return `${day[0]}  ${time[1]}`;
  }, []);

  const handleChangeValue = useCallback(
    (pIndex: number, cIndex: number) => (e: any, v: any) => {
      props.setProp(
        `NursingDiagnosisSequence.nursingDiagnosistemplateQuestionsList.${pIndex}.${v.name}.${cIndex}.value`,
        v.type === "checkbox" ? v.checked : v.value
      );
    },
    []
  );

  const handleChangeEndDate = useCallback(
    (pIndex: number) => (date: any) => {
      props.runSequence({
        sequence: "NursingDiagnosis",
        action: ACTIONS.HANDLE_CHANGE,
        encounterId: props.encounterId,
        date,
        pIndex,
      });
    },
    []
  );

  const handleChangeNursingDiagnosis = useCallback(
    (key: string) => (e: any) => {
      props.setProp(`NursingDiagnosisSequence.nursingDiagnosis.${key}`, e.target.value);
    },
    []
  );

  const handleChangeDate = useCallback(
    (key: string) => (date: string) => {
      props.setProp(`NursingDiagnosisSequence.nursingDiagnosis.${key}`, date);
    },
    []
  );

  const handleCheckedAll = useCallback(() => {
    if (isCheckAll) {
      setCheckBox([]);

      return;
    }

    const stateItem = (props.NursingDiagnosisSequence?.resultGroupList || []).flatMap((item) =>
      item.results.flatMap((result) => result.id)
    );

    setCheckBox(stateItem);
  }, [isCheckAll, props.NursingDiagnosisSequence?.resultGroupList]);

  const handleCheck = useCallback(
    (e: any, checked: any, row: any) => {
      let checkBoxArray = [...checkBox];

      if (checked) {
        checkBoxArray = [...checkBox, row.original.id];
      } else {
        checkBoxArray.splice(checkBox.indexOf(row.original.id), 1);
      }

      setCheckBox(checkBoxArray);
    },
    [checkBox]
  );

  const renderCheckbox = useCallback(
    (row: any) => (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Checkbox
          checked={checkBox.includes(row.original.id)}
          onChange={(e, { checked }) => {
            handleCheck(e, checked, row);
          }}
        />
      </div>
    ),
    [handleCheck]
  );

  const handleDelete = useCallback(
    (id: number) => (e: SyntheticEvent) => {
      e.stopPropagation();

      props.runSequence({
        sequence: "NursingDiagnosis",
        action: ACTIONS.DELETE,
        encounterId: props.encounterId,
        id,
        card: CARD_NURSING_DIAGNOSIS,
      });
    },
    []
  );

  // Memo
  const nursing = props.NursingDiagnosisSequence?.nursingDiagnosis;

  const columnsNursingDiagnosisList = useMemo(() => {
    const columnsData = [
      {
        accessor: "checked",
        Header: "",
        width: 60,
        Cell: renderCheckbox,
      },
      {
        accessor: "createdDate",
        Header: "วันที่",
        width: 135,
      },
      {
        accessor: "diagnosis",
        Header: "ข้อวินิจฉัยทางการพยาบาล",
        Cell: ({ original }: any = {}) => (
          <div
            dangerouslySetInnerHTML={{
              __html: original?.diagnosis,
            }}
          />
        ),
      },
      {
        accessor: "plan",
        Header: "แผนการพยาบาล",
        Cell: ({ original }: any = {}) => (
          <div
            dangerouslySetInnerHTML={{
              __html: original?.plan,
            }}
          />
        ),
      },
      {
        accessor: "goal",
        Header: "ผลลัพธ์ที่คาดหวัง",
        Cell: ({ original }: any = {}) => (
          <div
            dangerouslySetInnerHTML={{
              __html: original?.goal,
            }}
          />
        ),
      },
      {
        accessor: "end_date",
        Header: "วันที่สิ้นสุดปัญหา",
        width: 135,
      },
      {
        accessor: "edit_user_name",
        Header: "ชื่อผู้บันทึก",
      },
    ];

    if (!props.isPHV) {
      columnsData.push({
        accessor: "menu",
        Header: "",
      });
    }
    return columnsData;
  }, [checkBox, handleCheck, renderCheckbox, props.isPHV]);

  const formattedResultGroupList = useMemo(() => {
    const results = (props.NursingDiagnosisSequence?.resultGroupList || []).flatMap(
      (item) => item.results
    );

    return results.map((item) => ({
      ...item,
      createdDate: (
        <div style={{ display: "inline", justifyContent: "center" }}>
          {splitCreate(item.created) || ""}
        </div>
      ),
      menu: (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            color="yellow"
            content="Edit"
            size="mini"
            onClick={async () => {
              await props.runSequence({
                sequence: "NursingDiagnosis",
                action: ACTIONS.SELECT_EDIT,
                encounterId: props.encounterId,
                data: item,
              });
              setOpenEdit(true);
              setOpenCreate(false);
            }}
          />
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            // config
            color="red"
            name={ACTIONS.DELETE}
            // data
            paramKey={`${BTN_ACTS.DELETE}_${item.id}`}
            size="mini"
            title="Delete"
            buttonLoadCheck={props.buttonLoadCheck?.[`${BTN_ACTS.DELETE}_${item.id}`]}
            onClick={handleDelete(item.id)}
          />
        </div>
      ),
    }));
  }, [props.buttonLoadCheck, props.NursingDiagnosisSequence?.resultGroupList]);

  const nursingDiagnosistemplateQuestionsList = useMemo(
    () =>
      props.NursingDiagnosisSequence?.nursingDiagnosistemplateQuestionsList?.map(
        (item, pIndex) => ({
          diagnosis: (
            <div style={{ display: "inline", justifyContent: "center" }}>
              <div style={{ fontWeight: "bold", padding: "10px 0px 20px 10px" }}>{item.name}</div>
              {item.items.map((itemData, cIndex) =>
                itemData.column === 1 ? (
                  <DynamicFormField
                    key={itemData.id}
                    cIndex={cIndex}
                    data={itemData}
                    pIndex={pIndex}
                    onChangeValue={handleChangeValue}
                  />
                ) : null
              )}
            </div>
          ),
          end_date: (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                minHeight: "300px",
                paddingTop: "10px",
              }}
            >
              <DateTextBox value={item.end_date} onChange={handleChangeEndDate(pIndex)} />
            </div>
          ),
          goal: (
            <div style={{ display: "inline", justifyContent: "center" }}>
              <div style={{ paddingTop: "40px" }}>
                {item.items.map((itemData, cIndex) =>
                  itemData.column === 3 ? (
                    <DynamicFormField
                      key={itemData.id}
                      cIndex={cIndex}
                      data={itemData}
                      pIndex={pIndex}
                      onChangeValue={handleChangeValue}
                    />
                  ) : null
                )}
              </div>
            </div>
          ),
          plan: (
            <div style={{ display: "inline", justifyContent: "center" }}>
              <div style={{ paddingTop: "40px" }}>
                {item.items.map((itemData, cIndex) =>
                  itemData.column === 2 ? (
                    <DynamicFormField
                      key={itemData.id}
                      cIndex={cIndex}
                      data={itemData}
                      pIndex={pIndex}
                      onChangeValue={handleChangeValue}
                    />
                  ) : null
                )}
              </div>
            </div>
          ),
        })
      ),
    [props.NursingDiagnosisSequence?.nursingDiagnosistemplateQuestionsList]
  );

  // #const nursingDiagnosisList = useMemo(
  //   () =>
  //     props.NursingDiagnosisSequence?.nursingDiagnosisList
  //       ?.filter((acc: any) => acc?.results?.length !== 0)
  //       .map((item: any, index: number) => ({
  //         ...item,
  //         createdDate: (
  //           <div style={{ display: "inline", justifyContent: "center" }}>
  //             {splitCreate(item?.created)}
  //           </div>
  //         ),
  //         edit_user_name: (
  //           <div style={{ display: "inline", justifyContent: "center" }}>
  //             {item?.results?.[0]?.edit_user_name || "-"}
  //           </div>
  //         ),
  //       })),
  //   [props.NursingDiagnosisSequence?.nursingDiagnosisList]
  // );

  // Handler
  const handleTemplate = () => {
    props.runSequence({
      sequence: "NursingDiagnosis",
      action: ACTIONS.TEMPLATE_LIST,
      encounterId: props.encounterId,
    });

    setOpenModalTemplate(true);
  };

  const handleAdd = () => {
    props.runSequence({
      sequence: "NursingDiagnosis",
      action: ACTIONS.ADD,
      encounterId: props.encounterId,
    });

    setOpenCreate(true);
    setOpenEdit(false);
  };

  const handleClickPrint = () => {
    console.log("print clicked");

    props.runSequence({
      sequence: "NursingDiagnosis",
      action: ACTIONS.PRINT,
      encounterId: props.encounterId,
      card: CARD_NURSING_DIAGNOSIS,
      diagResults: checkBox,
    });
  };

  const handleSaveTemplate = () => {
    props.runSequence({
      sequence: "NursingDiagnosis",
      action: ACTIONS.SAVE_TEMPLATE,
      encounterId: props.encounterId,
      card: CARD_NURSING_DIAGNOSIS,
    });
  };

  const handleSearch = () => {
    props.runSequence({
      sequence: "NursingDiagnosis",
      action: ACTIONS.GET_DATA,
      encounterId: props.encounterId,
      card: CARD_NURSING_DIAGNOSIS,
    });
  };

  console.log("NursingDiagnosis", props, checkBox);

  return (
    <div>
      <SnackMessage
        onEvent={props.onEvent}
        error={props.errorMessage?.NursingDiagnosis}
        success={props.successMessage?.NursingDiagnosis}
        onClose={() => {
          props.runSequence({
            sequence: "NursingDiagnosis",
            action: ACTIONS.CLEAR,
            encounterId: props.encounterId,
          });
        }}
        languageUX={props.languageUX}
      />

      <ModMedReconcileAlert
        onEvent={props.onEvent}
        setProp={props.setProp}
        divisionType={props.divisionType}
        django={props.django}
        medReconcileCheck={props.medReconcileCheck}
        medReconcileIndex={props.medReconcileIndex}
        open={props.medReconcileCheck?.open_card_med_reconciliation}
        selectedEncounter={props.selectedEncounter}
        languageUX={props.languageUX}
      />

      {/* #<Modal
        open={openModal}
        size={"large"}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <Dimmer.Dimmable>
          <Dimmer active={props.NursingDiagnosisSequence?.nursingDiagnosisLoading} inverted>
            <Loader />
          </Dimmer>
          <CardNursingDiagnosisSearchUX
            changeDate={changeDateSearch}
            changeNursingDiagnosis={changeNursingDiagnosisSearch}
            division={nursingSearch?.division || ""}
            end_date={nursingSearch?.end_date || formatDate(moment())}
            nursingDiagnosisSearch={nursingDiagnosisList || []}
            start_date={nursingSearch?.start_date || formatDate(moment())}
            divisionOptions={props.masterOptions?.division || []}
            onSearch={(e: any) => {
              props.runSequence({
                sequence: "NursingDiagnosis",
                action: "search",
              });
            }}
            rowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
              style: {
                backgroundColor:
                  props.NursingDiagnosisSequence?.nursingDiagnosisSelected &&
                  rowInfo?.original?.id &&
                  rowInfo?.original?.id ===
                    props.NursingDiagnosisSequence.nursingDiagnosisSelected.id
                    ? "#d6ecf3"
                    : "white",
              },
              onClick: async () => {
                await props.runSequence({
                  sequence: "NursingDiagnosis",
                  action: "select",
                  item: rowInfo?.original,
                });
                setOpenModal(false);
              },
            })}
          />
        </Dimmer.Dimmable>
      </Modal> */}

      <Modal
        open={openModalTemplate}
        size={"fullscreen"}
        style={{ marginLeft: "2%" }}
        onClose={() => {
          setOpenModalTemplate(false);
        }}
      >
        <CardNursingDiagnosisTemplateUX
          nursingDiagnosisTemplate={nursingDiagnosistemplateQuestionsList}
          templateID={nursing?.templateID || ""}
          templateOptions={props.NursingDiagnosisSequence?.nursingDiagnosisTemplateOptions}
          buttonSave={
            <ButtonLoadCheck
              setProp={props.setProp}
              color="green"
              name={ACTIONS.SAVE_TEMPLATE}
              paramKey={BTN_ACTS.SAVE_TEMPLATE}
              size="small"
              title={"SAVE"}
              buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.SAVE_TEMPLATE]}
              onClick={handleSaveTemplate}
            />
          }
          changeNursingDiagnosis={(key: string) => (e: any, v: any) => {
            props.setProp(`NursingDiagnosisSequence.nursingDiagnosis.${key}`, v.value);
          }}
          languageUX={props.languageUX}
        />
      </Modal>

      <CardLayout closeable={false} titleText={"Nursing Diagnosis"} toggleable={false}>
        <CardNursingDiagnosisUX
          isPHV={props.isPHV}
          checked={isCheckAll}
          columns_nursingDiagnosisList={columnsNursingDiagnosisList}
          nursingDiagnosisList={formattedResultGroupList}
          onChangeChecked={handleCheckedAll}
          // nursingDiagnosisID={
          //   <Input
          //     value={
          //       props.NursingDiagnosisSequence?.nursingDiagnosisSelected?.id ||
          //       "NEW"
          //     }
          //     disabled={true}
          //     style={{ border: "solid 1px rgb(200, 200, 200, 0.5)" }}
          //   />
          // }
          onTemplate={handleTemplate}
          addButton={
            <Button color="yellow" circular icon onClick={handleAdd}>
              <Icon name="add" />
            </Button>
          }
          buttonPrint={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              // config
              color="blue"
              name={ACTIONS.PRINT}
              // data
              paramKey={BTN_ACTS.PRINT}
              title={intl.formatMessage({ id: "พิมพ์" })}
              buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.PRINT]}
              onClick={handleClickPrint}
            />
          }
          searchButton={
            <ButtonLoadCheck
              // function
              setProp={props.setProp}
              // config
              color="blue"
              // data
              paramKey={BTN_ACTS.GET_DATA}
              size="small"
              title={intl.formatMessage({ id: "ค้นหา" })}
              buttonLoadCheck={props.buttonLoadCheck?.[BTN_ACTS.GET_DATA]}
              onClick={handleSearch}
            />
          }
          languageUX={props.languageUX}
        />
        {(openCreate || openEdit) && (
          <CardLayout
            style={{ marginTop: "10px" }}
            titleText={"เพิ่ม/แก้ไขข้อมูล Nursing Diagnosis"}
            toggleable={false}
            closeable
            onClose={() => {
              setOpenCreate(false);
              setOpenEdit(false);
            }}
          >
            <CardNursingDiagnosisEditUX
              changeDate={handleChangeDate}
              changeNursingDiagnosis={handleChangeNursingDiagnosis}
              diagnosis={htmlParse(nursing?.diagnosis || "")}
              end_date={nursing?.end_date || ""}
              goal={htmlParse(nursing?.goal || "")}
              plan={htmlParse(nursing?.plan || "")}
              buttonSave={
                <ButtonLoadCheck
                  setProp={props.setProp}
                  color={openEdit ? "yellow" : "green"}
                  paramKey={openEdit ? BTN_ACTS.UPDATE : BTN_ACTS.SAVE}
                  size="small"
                  title={openEdit ? "EDIT" : "SAVE"}
                  buttonLoadCheck={
                    props.buttonLoadCheck?.[openEdit ? BTN_ACTS.UPDATE : BTN_ACTS.SAVE]
                  }
                  onClick={() => {
                    props.runSequence({
                      sequence: "NursingDiagnosis",
                      action: openEdit ? ACTIONS.UPDATE : ACTIONS.SAVE,
                      encounterId: props.encounterId,
                      card: CARD_NURSING_DIAGNOSIS,
                    });
                  }}
                />
              }
              languageUX={props.languageUX}
            />
          </CardLayout>
        )}
      </CardLayout>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                    DynamicFormField;                   */

/* ------------------------------------------------------ */
interface DynamicFormFieldProps {
  data: any;
  pIndex: number;
  cIndex: number;
  onChangeValue: (
    pIndex: number,
    cIndex: number
  ) => (event: any, data: CheckboxProps | InputProps) => void;
}

const DynamicFormField = (props: DynamicFormFieldProps) => {
  const renderInput = () => {
    switch (props.data?.type) {
      case "CHECKBOX": {
        return (
          <Checkbox
            checked={props.data?.value}
            name="items"
            onChange={props.onChangeValue(props.pIndex, props.cIndex)}
          />
        );
      }
      case "DATE_TEXTBOX": {
        return (
          <DateTextBox
            name="items"
            value={props.data?.value}
            onChange={(date: string) => {
              props.onChangeValue(props.pIndex, props.cIndex)(null, { name: "items", value: date });
            }}
          />
        );
      }
      case "LABEL": {
        return <label style={{ paddingLeft: "10px" }}>{props.data?.value}</label>;
      }
      case "TEXTBOX": {
        return (
          <div>
            <label style={{ paddingRight: "10px" }}>{props.data?.name}</label>
            <Input
              name="items"
              value={props.data?.value}
              onChange={props.onChangeValue(props.pIndex, props.cIndex)}
            />
          </div>
        );
      }

      default: {
        return null;
      }
    }
  };

  const renderLabel = () => {
    if (props.data?.type !== "TEXTBOX") {
      return <label style={{ paddingLeft: "10px" }}>{props.data?.name}</label>;
    }

    return null;
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        padding: "0px 0px 10px 10px",
      }}
    >
      {renderInput()}
      {renderLabel()}
      <br />
    </div>
  );
};

CardNursingDiagnosis.displayName = "CardNursingDiagnosis";

export default React.memo(CardNursingDiagnosis);
