import React, { useEffect, useState, useMemo } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";

import moment from "moment";

// UX
import CardAssessmentBundleUX from "./CardAssessmentBundleUX";
import CardPatientAssessmentUX from "./CardPatientAssessmentUX";
import CardFallRiskAssessmentUX from "./CardFallRiskAssessmentUX";
import CardPainAssessmentUX from "./CardPainAssessmentUX";
import CardNutritionAssessmentUX from "./CardNutritionAssessmentUX";
import CardDischargeReassessment from "./CardDischargeReassessment";
import ModSaveAssessment from "./ModSaveAssessment";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import { useIntl } from "react-intl";

import {
  alcoholOptions,
  alcoholSpecificOptions,
  fallRiskNursingOptions,
  pregnancyPeriodOptions,
  tobaccoOptions,
  tobaccoSpecificOptions,
} from "react-lib/apps/HISV3/ADM/sequence/PreAssessment";

const CardAssessment = (props: any) => {
  const intl = useIntl();
  const [assessmentBefore, setAssessmentBefore] = useState(true);
  const [assessmentAfter, setAssessmentAfter] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [openSaveConfirm, setOpenSaveConfirm] = useState(false);
  const [textAlert, setTextAlert] = useState("");

  useEffect(() => {
    if (!Number.isInteger(props?.selectedEncounter?.patient_id)) return;
    props.runSequence({ sequence: "Assessment", restart: true });
  }, []);

  const choiceTriageLevel = useMemo(() => {
    return props.choiceTriage?.items.map((item: any) => ({
      key: item.id,
      value: item.id,
      text: item.value,
    }));
  }, [props.choiceTriage]);

  // General helpers
  const changeForm = (e: any, v?: any) => {
    if (v.type === "checkbox") {
      if (!v.checked) {
        props.setProp(`AssessmentSequence.patientAssessment.data`, {
          ...props.AssessmentSequence.patientAssessment.data,
          [`${v.name}`]: v.checked,
          [`${v.name}_specific`]: "",
          ...(v.name === "psychologicalV2" && {
            psychological_select: "",
            psychological_detail: [],
          }),
          ...(v.name === "spiritual" && {
            spiritual_detail: "",
          }),
        });
      } else {
        props.setProp(`AssessmentSequence.patientAssessment.data.${v.name}`, v.checked);
      }
    } else {
      props.setProp(`AssessmentSequence.patientAssessment.data`, {
        ...props.AssessmentSequence.patientAssessment?.data,
        [`${v.name}`]: v.value,
        ...(v.name === "psychological_detail" &&
          !(patientAssessment?.psychological_detail || []).includes(6) && {
            psychological_text: "",
          }),
        ...(v.name === "phyco_nurse_noteV2" &&
          patientAssessment?.phyco_nurse_noteV2 !== "REP" && {
            phyco_nurse_note_specific_detail: "",
          }),
        ...(v.name === "mdr_triage" &&
          patientAssessment?.mdr_triage !== "YES" && {
            mdr: "",
          }),
        ...(["phyco_nurse_note", "sus_nurse_note"].includes(v.name) &&
          patientAssessment?.[`${v.name}`] && {
            [`${v.name}_specific`]: "",
          }),
        ...(["alcohol", "tobacco", "narcotic"].includes(v.name) &&
          ["NO", "NA"].includes(patientAssessment?.[`${v.name}`]) && {
            [`${v.name}_specific`]: "",
          }),
        ...(v.name === "menstruation" &&
          !["LMP", "other"].includes(patientAssessment?.menstruation) && {
            lmp: "",
            menstruationOther: "",
          }),
      });
    }
  };

  const changeDate = (key: string) => (date: any) =>
    props.setProp(`AssessmentSequence.patientAssessment.data.${key}`, date);

  const changePregnancy = (e: any, v: any) => {
    props.setProp(`AssessmentSequence.cardPregnancyAssessment.${v.name}`, v.value);
    props.setProp(`AssessmentSequence.cardPregnancyAssessment`, {
      [`${v.name}`]: v.value,
      ...(props.AssessmentSequence?.cardPregnancyAssessment?.pregnancy_status !== 3 && {
        pregnancy_period: "",
      }),
    });
  };

  const handleAddition = (e: any, { value }: any) => {
    // setArrived((prevOptions: any) => [{ text: value, value }, ...prevOptions]);
  };

  // Patient assessment
  const patientAssessment = props.AssessmentSequence?.patientAssessment?.data || {};
  const patientAssessmentId = props.AssessmentSequence?.patientAssessmentId;

  const checkArrived = useMemo(() => {
    return (props.masterData?.arriveStatus || []).map((item: any) => ({
      key: item.code,
      value: item.code,
      text: item.name,
    }));
  }, [patientAssessment.arrive_status, props.masterData?.arriveStatus]);

  const removeRow = (form: string, rowIndex: number) => {
    const newData = (props.AssessmentSequence?.[form]?.data || []).filter(
      (item: any, index: number) => index !== rowIndex
    );
    props.setProp(`AssessmentSequence.${form}.data`, newData);
  };

  const handleSave = (params: any) => {
    props.runSequence(params);
    setOpenSave(false);
  };

  const handleSaveAssessment = () => {
    setTextAlert("");
    let patientAssessment = props.AssessmentSequence?.patientAssessment;
    let psychologicalDetail = (patientAssessment?.data?.psychological_detail || []).find(
      (item: any) => item === 6
    );

    if (
      psychologicalDetail === 6 &&
      (patientAssessment?.data?.psychological_text === "" ||
        patientAssessment?.data?.psychological_text === undefined)
    ) {
      setTextAlert({
        กรอกข้อมูลที่จำเป็น: ["ต้องระบุข้อมูล Psychological Assessment"],
      });
      return;
    }

    if (["CONFIRMED", "EDITED"].includes(patientAssessment?.status)) {
      setOpenSaveConfirm(true);
    } else {
      setOpenSave(true);
    }
  };

  const reassess = (form: string, data: any, setData: any) => () => {
    const newData = [
      ...(props.AssessmentSequence?.[form]?.data || []),
      {
        ...data,
        edit_user: props.DJANGO?.user?.id,
        user_fullname: props.DJANGO?.user?.full_name,
        date: moment().format("HH:mm DD/MM/YYYY"),
      },
    ];
    props.setProp(`AssessmentSequence.${form}.data`, newData);

    return setData({});
  };

  return (
    <>
      <ModSaveAssessment
        // data
        open={openSave}
        // config
        isSaveReassessment={false}
        // callback
        onSave={handleSave}
        onClose={() => setOpenSave(false)}
        languageUX={props.languageUX}
      />

      <ModConfirm
        openModal={openSaveConfirm}
        titleName={intl.formatMessage({ id: "ข้อความแจ้งเตือน" })}
        onDeny={() => {
          setOpenSaveConfirm(false);
        }}
        onApprove={() => {
          props.runSequence({
            sequence: "Assessment",
            card: "CardAssessment",
            action: "SAVE",
            status: "SAVE",
          });

          setOpenSaveConfirm(false);
        }}
        content={intl.formatMessage({ id: "ต้องการแก้ไขเอกสารที่ยืนยันแล้วใช่หรือไม่" })}
      />

      {!props.showHeader && (
        <div style={{ margin: "15px" }}>
          <Button
            id="CardAssessment-Button-BeforeAssessment"
            color={assessmentBefore ? "blue" : undefined}
            onClick={() => {
              setAssessmentBefore(true);
              setAssessmentAfter(false);
            }}
          >
            แบบประเมินแรกรับ
          </Button>
          <Button
            id="CardAssessment-Button-AfterAssessment"
            color={assessmentAfter ? "blue" : undefined}
            onClick={() => {
              setAssessmentBefore(false);
              setAssessmentAfter(true);
            }}
            style={{ marginLeft: "10px" }}
          >
            แบบประเมินหลังออกจากห้องตรวจ
          </Button>
        </div>
      )}

      {assessmentBefore && (
        <div>
          <CardAssessmentBundleUX
            disableSendToQueue={patientAssessmentId === null}
            buttonSaveCompleted={
              <ButtonLoadCheck
                // function
                setProp={props.setProp}
                onClick={handleSaveAssessment}
                // data
                paramKey={"CardAssessment_SAVE"}
                buttonLoadCheck={props.buttonLoadCheck?.["CardAssessment_SAVE"]}
                // config
                id="CardPatientAssessment-ButtonLoadCheck-Save"
                style={{ width: "100%" }}
                color="green"
                name="SAVE"
                title="Save Assessment Form"
              />
            }
            languageUX={props.languageUX}
          />
          <CardPatientAssessmentUX
            // errorMessage
            errorMessage={
              <div style={{ padding: "10px" }}>
                <ErrorMessage error={textAlert} />
              </div>
            }
            // Fields
            date={moment().format("HH:mm DD/MM/YYYY")}
            isShowPregnancy={
              props.selectedPatient?.gender === "F" &&
              props.selectedPatient?.age >= 15 &&
              props.selectedPatient?.age <= 50
            }
            isMenstruation={
              props.selectedPatient?.gender === "F" &&
              props.selectedPatient?.age >= 11 &&
              props.selectedPatient?.age <= 60
            }
            pregnancyAssessment={props.AssessmentSequence?.cardPregnancyAssessment}
            pregnancyPeriodOptions={pregnancyPeriodOptions}
            version={props.AssessmentSequence?.patientAssessment?.form_version}
            arrivedByOptions={checkArrived}
            triageLevelOptions={choiceTriageLevel}
            phychoNurseNoteOptions={phychoNurseNoteOptions}
            suspectedOptions={suspectedOptions}
            susNurseNoteOptions={susNurseNoteOptions}
            convulsionOptions={convulsionOptions}
            mdrOptions={mdrOptions}
            precautionOptions={precautionOptions}
            alcoholOptions={alcoholOptions}
            alcoholSpecificOptions={alcoholSpecificOptions}
            tobaccoOptions={tobaccoOptions}
            tobaccoSpecificOptions={tobaccoSpecificOptions}
            narcoticOptions={narcoticOptions}
            menstruationOptions={menstruationOptions}
            psychologicalDetailOptions={psychologicalDetailOptions}
            phycoNurseNoteOptions={phycoNurseNoteOptions}
            phycoNurseSpecificOptions={phycoNurseSpecificOptions}
            masterOptions={props.masterOptions}
            patientAssessment={patientAssessment}
            user={props.DJANGO?.user?.full_name}
            // Method
            changePregnancy={changePregnancy}
            changeForm={changeForm}
            changeDate={changeDate}
            handleAddition={handleAddition}
            onClear={() =>
              props.runSequence({
                sequence: "Assessment",
                action: "CLEAR",
              })
            }
            languageUX={props.languageUX}
          />
          <hr />
          <CardFallRiskAssessment
            AssessmentSequence={props.AssessmentSequence}
            reassess={reassess}
            removeRow={removeRow}
            setProp={props.setProp}
            languageUX={props.languageUX}
          />
          <hr />
          <CardPainAssessment
            AssessmentSequence={props.AssessmentSequence}
            masterOptions={props.masterOptions}
            reassess={reassess}
            removeRow={removeRow}
            setProp={props.setProp}
            DJANGO={props.DJANGO}
            languageUX={props.languageUX}
          />
          <hr />
          <CardNutritionAssessment
            AssessmentSequence={props.AssessmentSequence}
            masterOptions={props.masterOptions}
            reassess={reassess}
            removeRow={removeRow}
            setProp={props.setProp}
            DJANGO={props.DJANGO}
            languageUX={props.languageUX}
          />
        </div>
      )}

      {assessmentAfter && (
        <div>
          <CardDischargeReassessment
            onEvent={props.onEvent}
            setProp={props.setProp}
            runSequence={props.runSequence}
            DJANGO={props.DJANGO}
            selectedEncounter={props.selectedEncounter}
            masterOptions={props.masterOptions}
            AssessmentSequence={props.AssessmentSequence}
            choiceTriage={props.ChoiceTriage}
            patientInfo={props.patientInfo}
            languageUX={props.languageUX}
          />
        </div>
      )}
    </>
  );
};

export default CardAssessment;

export const CardFallRiskAssessment = (props: any) => {
  const [newFallRiskData, setNewFallRiskData] = useState<any>({});

  // Fall risk assessment helpers
  const changeFallRisk = (key: string) => (e: any, v: any) => {
    let newData: { [key: string]: string } = Object.assign({}, { ...newFallRiskData });
    newData[key] = v.value;
    setNewFallRiskData(newData);
  };

  const fallRiskAssessment = (props.AssessmentSequence?.fallRiskAssessment?.data || []).map(
    (item: any, index: number) => ({
      ...item,
      fall_risk_description: (
        <div>
          <div>
            {
              fallRiskOptions.find(
                (row: any) =>
                  row.value ===
                  (props.AssessmentSequence?.fallRiskAssessment?.form_version === "0.1"
                    ? item?.fall_risk
                    : item?.fall_riskV2)
              )?.text
            }
          </div>
          {item?.fall_riskHigValue?.map((values: number) => {
            if (values === 9) {
              return (
                <li>{`${fallRiskHigOptions.find((data: any) => data.value === values)?.text} : ${
                  item.fall_riskText
                }`}</li>
              );
            } else {
              return <li>{fallRiskHigOptions.find((data: any) => data.value === values)?.text}</li>;
            }
          })}
        </div>
      ),
      delete: (
        <div style={{ width: "100%", textAlign: "right" }}>
          <Button id={`FallRiskAssessment-Item-Delete-${index}`} color="red" onClick={(e: any) => props.removeRow("fallRiskAssessment", index)}>
            Delete
          </Button>
        </div>
      ),
    })
  );

  return (
    <CardFallRiskAssessmentUX
      // Fields
      version={props.AssessmentSequence?.fallRiskAssessment?.form_version}
      data={fallRiskAssessment}
      fallRiskOptions={fallRiskOptions}
      fallRiskNursingOptions={fallRiskNursingOptions}
      fallRiskHigOptions={fallRiskHigOptions}
      fall_risk={newFallRiskData?.fall_risk || ""}
      nursing={newFallRiskData?.nursing || ""}
      fall_riskHigValue={newFallRiskData?.fall_riskHigValue || ""}
      fall_riskText={newFallRiskData?.fall_riskText || ""}
      fall_riskV2={newFallRiskData?.fall_riskV2 || ""}
      // Methods
      changeFallRisk={changeFallRisk}
      onReassess={props.reassess("fallRiskAssessment", newFallRiskData, setNewFallRiskData)}
      languageUX={props.languageUX}
    />
  );
};

export const CardPainAssessment = (props: any) => {
  const [newPainData, setNewPainData] = useState<any>({});
  const [openEdit, setOpenEdit] = useState(false);
  const [rowID, setRowID] = useState("");

  useEffect(() => {
    setNewPainData(painDefaultData);
  }, [props.AssessmentSequence?.painAssessment?.data]);

  // Pain assessment helpers
  const changePain = (key: string) => (e: any, v: any) => {
    let newData: { [key: string]: string } = Object.assign({}, { ...newPainData });
    if (key === "location" && v.value !== "อื่นๆ") {
      newData["locationRemark"] = "";
    }
    newData[key] = v.value;
    setNewPainData(newData);
  };

  const painLocationOptions = (props.masterOptions?.location || []).map((item: any) => ({
    ...item,
    key: item?.text,
    value: item?.text,
  }));

  const painCharacteristicOptions = (props.masterOptions?.characteristic || []).map(
    (item: any) => ({ ...item, key: item?.text, value: item?.text })
  );

  const painAssessmentOptions = (props.masterOptions?.pain || []).map((item: any) => ({
    ...item,
    key: item?.key.toString(),
    value: item?.value.toString(),
  }));

  const painFrequencyOptions = (props.masterOptions?.painFreq || []).map((item: any) => ({
    ...item,
    key: item?.key.toString(),
    value: item?.value.toString(),
  }));

  const nursing_filter = (item: any) => {
    let nursing = item || [];
    let painOption = painNursingOptions;
    let newNursing = "";

    (nursing || [])?.map((acc: any, index: number) => {
      painOption?.map((items: any) => {
        if (items?.value === acc) {
          if (index === 0) {
            newNursing = `${items?.text}`;
          } else {
            newNursing = `${newNursing}, ${items?.text}`;
          }
        }
      });
    });
    return newNursing;
  };

  const painAssessment = useMemo(() => {
    return (props.AssessmentSequence?.painAssessment?.data || []).map(
      (item: any, index: number) => ({
        ...item,
        pain_score_desc: painScoreOptions.find((row: any) => row.value === item?.pain_score)?.text,
        pain_assessment_desc: painAssessmentOptions.find(
          (row: any) => row.value.toString() === item?.pain_assessment_tool
        )?.text,
        locationText: (
          <div>
            <div>{item.location}</div>
            {item.locationRemark && <div>{item.locationRemark}</div>}
          </div>
        ),
        frequency_desc: painFrequencyOptions.find(
          (row: any) => row.value.toString() === item?.frequency
        )?.text,
        // nursing_desc: painNursingOptions.find(
        //   (row: any) => row.value.toString() === item?.nursing
        // )?.text,
        nursing_desc: nursing_filter(item?.nursing),
        delete: (
          <div style={{ width: "100%", textAlign: "right" }}>
            <Button id={`PainAssessment-Item-Delete-${index}`} color="red" onClick={(e: any) => props.removeRow("painAssessment", index)}>
              Delete
            </Button>
          </div>
        ),
      })
    );
  }, [
    props.AssessmentSequence?.painAssessment?.data,
    painScoreOptions,
    painAssessmentOptions,
    painFrequencyOptions,
  ]);

  const reassessUpdate = (form: string, data: any, index: any) => () => {
    props.setProp(`AssessmentSequence.${form}.data.${rowID}`, {
      ...newPainData,
      edit_user: props.DJANGO?.user?.id,
      user_fullname: props.DJANGO?.user?.full_name,
      date: moment().format("HH:mm DD/MM/YYYY"),
    });
    setNewPainData(resetData);
    setOpenEdit(false);
    setRowID("");
  };

  return (
    <CardPainAssessmentUX
      // Fields
      data={painAssessment}
      newPainData={newPainData}
      painScoreOptions={painScoreOptions}
      painAssessmentOptions={painAssessmentOptions}
      painLocationOptions={painLocationOptions}
      painCharacteristicOptions={painCharacteristicOptions}
      painFrequencyOptions={painFrequencyOptions}
      painNursingOptions={painNursingOptions}
      // Methods
      changePain={changePain}
      onReassess={props.reassess("painAssessment", newPainData, setNewPainData)}
      openEdit={
        openEdit
          ? { gridRow: "37/40", gridColumn: "36/43", marginRight: "10px" }
          : { display: "none" }
      }
      updatePain={reassessUpdate("painAssessment", newPainData, rowID)}
      openClear={openEdit ? { gridRow: "37/40", gridColumn: "43/50" } : { display: "none" }}
      clearPain={() => {
        setNewPainData(resetData);
        setOpenEdit(false);
        setRowID("");
      }}
      rowProps={(state: any, rowInfo: any, column: any, instance: any) => ({
        style: {
          backgroundColor:
            props.NursingDiagnosisSequence?.nursingDiagnosisSelected &&
            rowInfo?.original?.id &&
            rowInfo?.original?.id === props.NursingDiagnosisSequence?.nursingDiagnosisSelected?.id
              ? "#d6ecf3"
              : "white",
        },
        onClick: async () => {
          if (column?.Header !== "Delete") {
            setRowID(rowInfo?.index);
            setOpenEdit(true);
            setNewPainData(rowInfo?.original);
          }
        },
      })}
      languageUX={props.languageUX}
    />
  );
};

export const CardNutritionAssessment = (props: any) => {
  const [newNutritionData, setNewNutritionData] = useState<any>({});

  useEffect(() => {
    setNewNutritionData(nutritionDefaultData);
  }, [props.AssessmentSequence?.nutritionAssessment?.data]);

  // Nutrition assessment helpers
  const changeNutrition = (key: string, value?: any) => (e: any, v: any) => {
    let newData: { [key: string]: string } = Object.assign({}, { ...newNutritionData });
    if (key === "result" && v.value !== "OTH") {
      newData["resultRemark"] = "";
    }
    newData[key] = typeof value !== "undefined" ? value : v.value;
    setNewNutritionData(newData);
  };

  const nutritionAssessment = useMemo(() => {
    return (props.AssessmentSequence?.nutritionAssessment?.data || []).map(
      (item: any, index: number) => {
        let result = resultOptions.find((row: any) => row.value.toString() === item?.result)?.text;

        return {
          ...item,
          lose_weight_desc: item.lose_weight ? "YES" : "NO",
          malnutrition_desc: item.malnutrition ? "YES" : "NO",
          bmi_outrange_desc: item.bmi_outrange ? "YES" : "NO",
          critical_desc: item.critical ? "YES" : "NO",
          result_desc: `${result} ${result === "อื่นๆ ระบุ" ? item?.resultRemark : ""}`,
          preg1_desc: item.preg1 ? "YES" : "NO",
          preg2_desc: item.preg2 ? "YES" : "NO",
          preg3_desc: item.preg3 ? "YES" : "NO",
          result_preg_desc: resultPregOptions.find(
            (row: any) => row.value.toString() === item?.result_preg
          )?.text,
          delete: (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                id={`NutritionAssessment-Item-Delete-${index}`}
                color="red"
                onClick={(e: any) => props.removeRow("nutritionAssessment", index)}
              >
                Delete
              </Button>
            </div>
          ),
        };
      }
    );
  }, [props.AssessmentSequence?.nutritionAssessment?.data, resultOptions, resultPregOptions]);

  return (
    <CardNutritionAssessmentUX
      // Fields
      data={nutritionAssessment}
      lose_weight={newNutritionData?.lose_weight || false}
      malnutrition={newNutritionData?.malnutrition || false}
      bmi_outrange={newNutritionData?.bmi_outrange || false}
      critical={newNutritionData?.critical || false}
      result={newNutritionData?.result || ""}
      resultRemark={newNutritionData?.resultRemark || ""}
      resultOptions={resultOptions}
      preg1={newNutritionData?.preg1 || false}
      preg2={newNutritionData?.preg2 || false}
      preg3={newNutritionData?.preg3 || false}
      result_preg={newNutritionData?.result_preg || ""}
      resultPregOptions={resultPregOptions}
      isShowPregnancy={props.AssessmentSequence?.cardPregnancyAssessment?.pregnancy_status === 3}
      // Methods
      changeNutrition={changeNutrition}
      onReassess={props.reassess("nutritionAssessment", newNutritionData, setNewNutritionData)}
      setProp={props.setProp}
      languageUX={props.languageUX}
    />
  );
};

const phychoNurseNoteOptions = [
  { key: "NON", value: "NON", text: "No Intervention Require" },
  { key: "REP", value: "REP", text: "Action Plan Require" },
  { key: "ND", value: "ND", text: "Notify Doctor" },
];

const suspectedOptions = [
  {
    key: "NON",
    value: "NON",
    text: "ไม่พบร่องรอยผิดปกติจากการประเมินโดยการสังเกต",
  },
  {
    key: "SUS1",
    value: "SUS1",
    text: "พบร่องรอยบาดแผล รอยฟกช้ำ ไม่สัมพันธ์กับการเจ็บป่วย รายงานแพทย์",
  },
  {
    key: "SUS2",
    value: "SUS2",
    text: "พบร่องรอยบาดแผล รอยฟกช้ำ มีอาการหวาดกลัว ซึมเศร้า ถามไม่ตอบหรือเหม่อลอย รายงานแพทย์",
  },
];

const susNurseNoteOptions = [
  { key: "NON", value: "NON", text: "ไม่จำเป็น" },
  { key: "REP", value: "REP", text: "รายงานแพทย์" },
  { key: "OTN", value: "OTN", text: "อื่นๆ" },
];

const convulsionOptions = [
  { key: "NA", value: "NA", text: "NA" },
  { key: "YES", value: "YES", text: "Yes" },
  { key: "NO", value: "NO", text: "No" },
];

export const mdrOptions = [
  { key: "NA", value: "NA", text: "NA" },
  { key: "YES", value: "YES", text: "Yes" },
  { key: "NO", value: "NO", text: "No" },
];

export const precautionOptions = [
  { key: "SP", value: "SP", text: "Standard Precaution" },
  { key: "AP", value: "AP", text: "Airborned Precaution" },
  { key: "DP", value: "DP", text: "Droplet Precaution" },
  { key: "CP", value: "CP", text: "Contact Precaution" },
];

export const narcoticOptions = [
  { key: "NA", value: "NA", text: "NA" },
  { key: "NO", value: "NO", text: "ไม่เคยใช้" },
  { key: "YES", value: "YES", text: "มีประวัติการใช้" },
];

const menstruationOptions = [
  { key: "NA", value: "NA", text: "NA" },
  { key: "LMP", value: "LMP", text: "LMP" },
  { key: "MN", value: "MN", text: "Menopause" },
  { key: "other", value: "other", text: "อื่นๆ" },
];

const psychologicalDetailOptions = [
  {
    key: 1,
    value: 1,
    text: "Suicidal Idea /มีความคิดทำร้ายตัวเอง ฆ่าตัวตาย",
  },
  {
    key: 2,
    value: 2,
    text: "Aggressive Behavior /มีพฤติกรรมก้าวร้าว",
  },
  {
    key: 3,
    value: 3,
    text: "หูแว่ว",
  },
  {
    key: 4,
    value: 4,
    text: "ภาพหลอน",
  },
  {
    key: 5,
    value: 5,
    text: "หวาดระแวง  กระวนกระวาย",
  },
  {
    key: 7,
    value: 7,
    text: "ใน 2 สัปดาห์ที่ผ่านมา รวมวันนี้ รู้สึกหดหู่ เศร้า หรือท้อแท้ สิ้นหวัง",
  },
  {
    key: 8,
    value: 8,
    text: "ใน 2 สัปดาห์ที่ผ่านมา รวมวันนี้ รู้สึกเบื่อ ทำอะไรก็ไม่เพลิดเพลิน",
  },
  {
    key: 6,
    value: 6,
    text: "อื่นๆ ระบุ",
  },
];

const phycoNurseNoteOptions = [
  { key: "NON", value: "NON", text: "No Intervention Require" },
  { key: "REP", value: "REP", text: "Action Plan Require" },
  { key: "ND", value: "ND", text: "Notify Doctor" },
];

const phycoNurseSpecificOptions = [
  { key: "SN", value: "SN", text: "Suicidal Precaution / Notify Doctor" },
  { key: "AN", value: "AN", text: "Aggressive Precaution / Notify Doctor" },
];

export const fallRiskHigOptions = [
  {
    key: 1,
    value: 1,
    text: "เด็กอายุ < 7 ปี",
  },
  {
    key: 2,
    value: 2,

    text: "ระดับความรู้สึกตัว ไม่ปกติ เช่น สับสน ซึม อาละวาด วุ่นวาย",
  },
  {
    key: 3,
    value: 3,
    text: "มีประวัติพลัดตกหกล้มภายใน 6 เดือน",
  },
  {
    key: 4,
    value: 4,
    text: "การทรงตัวไม่ดี ต้องช่วยเหลือในการลุกยืน หรือไม่สามารถนั่งได้ถ้าไม่มีการพิง ใช้เครื่องช่วยเดิน หรือใช้รถเข็น",
  },
  {
    key: 5,
    value: 5,
    text: "การมองเห็นบกพร่อง ที่มีผลต่อการทรงตัว",
  },
  {
    key: 6,
    value: 6,
    text: "ได้รับยาที่มีผลต่อระดับความรู้สึกตัวและการทรงตัว ได้แก่ยานอนหลับ ยาคลายเครียด ยากล่อมประสาท",
  },
  {
    key: 7,
    value: 7,
    text: "เป็นสถานที่ที่ถูกกำหนดให้เป็นพื่นที่ความเสี่ยงต่อการเกิด Fall",
  },
  {
    key: 8,
    value: 8,
    text: "ระดับการคัดกรอง เป็น Level 1 หรือ 2",
  },
  {
    key: 9,
    value: 9,
    text: "อื่นๆ ระบุ",
  },
  {
    key: 10,
    value: 10,
    text: "พัฒนาการช้าและหรือมีปัญหาทางด้านระบบประสาท การทรงตัว การเคลื่อนไหวและการมองเห็น",
  },
];

export const fallRiskOptions = [
  { key: "LOW", value: "LOW", text: "Low Risk" },
  { key: "HIG", value: "HIG", text: "High Risk" },
];

// const fallRiskNursingOptions = [
//   { key: "NON", value: "ไม่จำเป็น", text: "ไม่จำเป็น" },
//   {
//     key: "RSK",
//     value: "ปฏิบัติตามการให้การพยาบาลผู้ป่วยที่ประเมินแล้วมีความเสี่ยงต่อการพลัดตกหกล้ม",
//     text: "ปฏิบัติตามการให้การพยาบาลผู้ป่วยที่ประเมินแล้วมีความเสี่ยงต่อการพลัดตกหกล้ม",
//   },
//   {
//     key: "TRS",
//     value: "จัดให้ผู้ป่วยนั่งรถนั่งหรือนอนเตียง Transfer",
//     text: "จัดให้ผู้ป่วยนั่งรถนั่งหรือนอนเตียง Transfer",
//   },
//   {
//     key: "ORA",
//     value: "ติดสัญลักษณ์แสดงผู้ป่วยที่เสี่ยงต่อการพลัดตกหกล้ม",
//     text: "ติดสัญลักษณ์แสดงผู้ป่วยที่เสี่ยงต่อการพลัดตกหกล้ม",
//   },
//   {
//     key: "SPL",
//     value: "แจ้งผู้ป่วยและญาติระวังพลัดตกหกล้ม",
//     text: "แจ้งผู้ป่วยและญาติระวังพลัดตกหกล้ม",
//   },
//   {
//     key: "LIM",
//     value: "จัดให้ผู้ป่วยอยู่ในบริเวณที่กำหนด",
//     text: "จัดให้ผู้ป่วยอยู่ในบริเวณที่กำหนด",
//   },
//   {
//     key: "STD",
//     value: "Standard Fall Precaution",
//     text: "Standard Fall Precaution",
//   },
//   { key: "OTH", value: "อื่นๆ", text: "อื่นๆ" },
// ];

const painScoreOptions = [
  { key: "0", value: "0", text: "0" },
  { key: "1", value: "1", text: "1" },
  { key: "2", value: "2", text: "2" },
  { key: "3", value: "3", text: "3" },
  { key: "4", value: "4", text: "4" },
  { key: "5", value: "5", text: "5" },
  { key: "6", value: "6", text: "6" },
  { key: "7", value: "7", text: "7" },
  { key: "8", value: "8", text: "8" },
  { key: "9", value: "9", text: "9" },
  { key: "10", value: "10", text: "10" },
  { key: "NA", value: "NA", text: "ไม่สามารถประเมินได้" },
];

const painNursingOptions = [
  { key: "NA", value: "NA", text: "NA" },
  { key: "NON", value: "NON", text: "ไม่จำเป็น" },
  { key: "REL", value: "REL", text: "จัดท่าเพื่อลดความเจ็บปวด" },
  { key: "HCP", value: "HCP", text: "วาง Hot/Cold pack" },
  { key: "REP", value: "REP", text: "รายงานแพทย์ pain score > 3" },
  { key: "YRX", value: "YRX", text: "มี Rx" },
  { key: "NRX", value: "NRX", text: "ไม่มี Rx" },
  { key: "Other", value: "Other", text: "อื่น ๆ" },
];

const resultOptions = [
  { key: "Normal", value: "Normal", text: "ปกติ" },
  { key: "Abnormal1", value: "Abnormal1", text: "ผิดปกติ รายงานแพทย์" },
  { key: "OTH", value: "OTH", text: "อื่นๆ ระบุ" },
  {
    key: "Abnormal",
    value: "Abnormal",
    text: "ผิดปกติ ส่งปรึกษาทีมโภชนบำบัด",
  },
];

const resultPregOptions = [
  { key: "NA", value: "NA", text: "NA" },
  { key: "Normal", value: "Normal", text: "ปกติ" },
  { key: "Abnormal1", value: "Abnormal1", text: "ผิดปกติรายงานแพทย์" },
];

const painDefaultData = {
  pain_score: "",
  pain_assessment_tool: "",
  location: "",
  locationRemark: "",
  characteristic: "",
  frequency: "",
  duration_min: "",
  duration_sec: "",
  nursing: "",
  nursing_remark: "",
};

const nutritionDefaultData = {
  bmi_outrange: false,
  critical: false,
  lose_weight: false,
  malnutrition: false,
  result: "",
  resultRemark: "",
};

const resetData = {
  pain_score: "",
  pain_assessment_tool: "",
  location: "",
  locationRemark: "",
  characteristic: "",
  frequency: "",
  duration_min: "",
  duration_sec: "",
  nursing: "",
  nursing_remark: "",
};
