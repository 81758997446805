import React, { useEffect, useState, useMemo, useCallback, useRef, MutableRefObject } from "react";
import { Icon, Button, Modal, TextArea } from "semantic-ui-react";

import ModIntraTransferUX from "../ADM/ModIntraTransferUX";
import CardIntraHospitalTransferFormUX from "../ADM/CardIntraHospitalTransferFormUX";
import TimeComboBox from "react-lib/apps/common/TimeComboBox";
import EmployeeToken from "react-lib/apps/common/EmployeeToken";
import SearchBox from "react-lib/appcon/common/SearchBox";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import DeleteColumn from "react-lib/appcon/common/DeleteColumn";
import CardSelectVitalSign from "react-lib/apps/ER/CardSelectVitalSign";
import { useIntl } from "react-intl";

const CARD_INTRA_HOSPITAL_TRANSFER = "CardIntraHospitalTransfer";
const CARD_QUEUE_WARD = "CardWardQueue";

const CardIntraHospitalTransfer = (props: any) => {
  const intl = useIntl();
  const [doctor, setDoctor] = useState<any>(null);
  const [complete, setComplete] = useState<string>("");
  const [openModVitalSign, setOpenModVitalSign] = useState<boolean>(false);

  const transferringTimeRef = useRef() as MutableRefObject<any>;
  const receivingTimeRef = useRef() as MutableRefObject<any>;

  useEffect(() => {
    props.runSequence({
      sequence: "WardQueue",
      restart: props.isRestartClear,
      nextSequenceIndex: "AdmitAction",
    });
    return () => {
      if (props.isRestartClear) {
        props.runSequence({ sequence: "WardQueue", clear: true });
      }
      props.setProp("intraTransferForm", { show: false });
    };
  }, []);

  useEffect(() => {
    handleChangeValue(null, {
      value: props.intraTransferForm?.receivingTokenize?.token || "",
      name: "receiving_personnel",
    });
  }, [props.intraTransferForm?.receivingTokenize]);

  useEffect(() => {
    handleChangeValue(null, {
      value: props.intraTransferForm?.transferringTokenize?.token || "",
      name: "transferring_personnel",
    });
  }, [props.intraTransferForm?.transferringTokenize]);

  useEffect(() => {
    const change = async () => {
      await handleChangeValue(null, {
        value: doctor?.id || null,
        name: "doctor",
      });
      await handleChangeValue(null, {
        value: doctor?.name_code || "",
        name: "doctor_name_code",
      });
    };

    change();
  }, [doctor]);

  useEffect(() => {
    if (props.intraTransferForm?.id && props.intraTransferForm?.transferringTokenize) {
      if (props.intraTransferForm?.doctor) {
        setDoctor({
          id: props.intraTransferForm?.doctor || null,
          name_code: props.intraTransferForm?.doctor_name_code || "",
        });
      } else {
        setDoctor({
          id: props.selectedEncounter?.doctor || null,
          name_code:
            props.selectedEncounter?.doctor_name_code || props.selectedEncounter?.doctor_name || "",
        });
      }
    }
  }, [props.intraTransferForm?.transferringTokenize, props.selectedEncounter]);

  useEffect(() => {
    const loading = props.loadingStatus?.[CARD_INTRA_HOSPITAL_TRANSFER];
    if (loading === null || loading === undefined) {
      setComplete(loading === null ? "check" : "close");

      setTimeout(() => {
        setComplete("");
        props.setProp(`loadingStatus.${CARD_INTRA_HOSPITAL_TRANSFER}`, false);
      }, 2000);
    }
  }, [props.loadingStatus]);

  const handleGetTrProps = useCallback(
    (state: any, rowInfo: any, column: any, instance: any) => ({
      style: {
        backgroundColor:
          Number.isInteger(props.intraTransferForm?.id) &&
          rowInfo?.original?.id === props.intraTransferForm?.id
            ? "#cccccc"
            : "white",
      },
      onClick: async (e: any) => {
        props.setProp(`errorMessage.${CARD_INTRA_HOSPITAL_TRANSFER}`, null);

        props.runSequence({
          sequence: "WardQueue",
          item: props.intraTransferForm?.items?.[rowInfo.index],
          action: "selected",
        });
      },
    }),
    [props.intraTransferForm?.items, props.intraTransferForm?.id]
  );

  const transferFormData = useMemo(() => {
    return (props.intraTransferForm?.items || []).map((item: any) => ({
      ...item,
      date: item.created,
      from_division: item.from_div_name,
      to_division: item.to_div_name,
      _remove: (
        <DeleteColumn
          id={item.id}
          value={item.id}
          size="mini"
          titleColor="orange"
          titleName={intl.formatMessage({ id: "ต้องการยกเลิกใช่หรือไม่" })}
          content={<div style={{ margin: "-2rem 0" }}></div>}
          onConfirm={() =>
            props.runSequence({
              sequence: "WardQueue",
              action: "save_intra",
              card: CARD_INTRA_HOSPITAL_TRANSFER,
              deleteItem: item,
              patientId: props.patientId,
            })
          }
          languageUX={props.languageUX}
        />
      ),
    }));
  }, [props.intraTransferForm?.items]);

  const handleChangeValue = async (e: any, v: any) => {
    let intraTransferData: any = {};
    const value = typeof v.checked === "boolean" ? v.checked : v.value;

    if (v.name === "bleeding_precaution" && value === false) {
      intraTransferData[`${v.name}`] = value;
      intraTransferData["bleeding_precaution_inr"] = "";
      intraTransferData["bleeding_precaution_plt"] = "";
      intraTransferData["bleeding_precaution_hct"] = "";
      intraTransferData["bleeding_precaution_hb"] = "";
    } else if (v.name === "protection" && value === false) {
      intraTransferData[`${v.name}`] = value;
      intraTransferData["protection_detail"] = "";
    } else if (v.name === "pain" && value === false) {
      intraTransferData[`${v.name}`] = value;
      intraTransferData["pain_score"] = "";
    } else if (v.name === "intervention" && value === false) {
      intraTransferData[`${v.name}`] = value;
      intraTransferData["intervention_detail"] = "";
    } else if (v.name === "post_transfer_assessment" && value === false) {
      intraTransferData[`${v.name}`] = value;
      intraTransferData["post_transfer_assessment_detail"] = "";
    } else {
      intraTransferData[`${v.name}`] = value;
    }
    props.runSequence({
      sequence: "WardQueue",
      action: "set_data",
      data: intraTransferData,
    });
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "WardQueue",
      action: "save_intra",
      card: CARD_INTRA_HOSPITAL_TRANSFER,
      patientId: props.patientId,
    });
  };

  const handlePrint = () => {
    props.runSequence({
      sequence: "WardQueue",
      action: "print",
      card: CARD_INTRA_HOSPITAL_TRANSFER,
    });
  };

  const handleSelectVitalSign = ({ row }: any = {}) => {
    var values = row.original.vital_signs.reduce((o: any, cur: any) => {
      o[cur.vitalsign_type_code] = cur.result;
      return o;
    }, {});

    const getHeartRate = () => {
      const keys = ["HR", "HR12+", "HR6-12", "HR3-6", "HR1-3"];
      const field = keys.find((key) => values[key]) || "";

      return values[field] || "";
    };

    props.setProp("intraTransferForm", {
      ...props.intraTransferForm,
      vital_bp: (values.SP || "") + "/" + (values.DP || ""),
      vital_body_temp: values.BT || values.TEMP || "",
      vital_o2_sat: values.O2Sat || "",
      vital_heart_rate: values.PR || values.PULSE || "",
      vital_respiratory_rate: values.RR || "",
    });

    setOpenModVitalSign(false);
  };

  return (
    <div>
      <ModIntraTransferUX
        hideAdmit={props.hideAdmit}
        // function
        onNewForm={() => {
          props.runSequence({
            sequence: "WardQueue",
            action: "new_form",
            patientId: props.patientId,
            // card: CARD_QUEUE_WARD,
          });
        }}
        getTrProps={handleGetTrProps}
        transferFormData={transferFormData}
        intraTransferForm={
          props.intraTransferForm?.show && (
            <>
              <ErrorMessage
                style={{ margin: "1rem 0 0.5rem" }}
                error={props.errorMessage?.[CARD_INTRA_HOSPITAL_TRANSFER]}
              />
              <CardIntraHospitalTransferFormUX
                data={props.intraTransferForm || {}}
                disablePrint={!props.intraTransferForm?.id}
                divisionList={props.masterOptions.division}
                reasonToTransferChoicesOptions={
                  props.intraHospitalTransferOptions?.reason_to_transfer_choices || []
                }
                modeOfTransferOptions={
                  props.intraHospitalTransferOptions?.mode_of_transfer_choices || []
                }
                isolationPrecautionOptions={
                  props.intraHospitalTransferOptions?.isolation_precaution_choices || []
                }
                typeOfTransferOptions={[
                  ...(props.intraHospitalTransferOptions?.type_of_transfer_choices || []),
                  {
                    key: "Interdependent",
                    text: "Interdependent",
                    value: "Interdependent",
                  },
                  {
                    key: "Dependent",
                    text: "Dependent",
                    value: "Dependent",
                  },
                ]}
                conciousnessLevelOptions={
                  props.intraHospitalTransferOptions?.conciousness_level_choices || []
                }
                protectionOptions={props.intraHospitalTransferOptions?.protection_choices || []}
                specialCareOptions={props.intraHospitalTransferOptions?.special_care_choices || []}
                // callback
                onChangeValue={handleChangeValue}
                onPrint={handlePrint}
                onSelectVitalSign={() => setOpenModVitalSign(true)}
                // Element
                transferringTimeBox={
                  <TimeComboBox
                    ref={transferringTimeRef}
                    defaultValue={props.intraTransferForm?.transferring_personnel_time || ""}
                    onTextChange={(value: string) =>
                      handleChangeValue(null, {
                        name: "transferring_personnel_time",
                        value,
                      })
                    }
                  />
                }
                receivingTimeBox={
                  <TimeComboBox
                    ref={receivingTimeRef}
                    defaultValue={props.intraTransferForm?.receiving_personnel_time || ""}
                    onTextChange={(value: string) =>
                      handleChangeValue(null, {
                        name: "receiving_personnel_time",
                        value,
                      })
                    }
                  />
                }
                transferringEmployee={
                  <EmployeeToken
                    placeholder={intl.formatMessage({ id: "ระบุ code employee" })}
                    fluid={true}
                    onEnterToken={(code) => {
                      props.runSequence({
                        sequence: "WardQueue",
                        action: "tokenize",
                        code,
                        key: "transferringTokenize",
                      });
                    }}
                    onClearToken={async () => {
                      await props.setProp("intraTransferForm.transferringTokenize", {});
                      await props.setProp("intraTransferForm.transferring_personnel", "");
                    }}
                    error={props.intraTransferForm?.transferringTokenize?.error}
                    loading={props.intraTransferForm?.transferringTokenize?.loading}
                    employeeName={props.intraTransferForm?.transferringTokenize?.name}
                    inputStyle={{
                      width:
                        props.intraTransferForm?.transferringTokenize?.name ||
                        props.intraTransferForm?.transferringTokenize?.loading
                          ? "calc(100% - 1.4rem)"
                          : "",
                    }}
                  />
                }
                receivingEmployee={
                  <EmployeeToken
                    placeholder={intl.formatMessage({ id: "ระบุ code employee" })}
                    fluid={true}
                    onEnterToken={(code) => {
                      props.runSequence({
                        sequence: "WardQueue",
                        action: "tokenize",
                        code,
                        key: "receivingTokenize",
                      });
                    }}
                    onClearToken={async () => {
                      await props.setProp("intraTransferForm.receivingTokenize", {});
                      await props.setProp("intraTransferForm.receiving_personnel", "");
                    }}
                    error={props.intraTransferForm?.receivingTokenize?.error}
                    loading={props.intraTransferForm?.receivingTokenize?.loading}
                    employeeName={props.intraTransferForm?.receivingTokenize?.name}
                    inputStyle={{
                      width:
                        props.intraTransferForm?.receivingTokenize?.name ||
                        props.intraTransferForm?.receivingTokenize?.loading
                          ? "calc(100% - 1.4rem)"
                          : "",
                    }}
                  />
                }
                patientDoctor={
                  <SearchBox
                    onEvent={props.onEvent}
                    searchedItemList={props.searchedItemList}
                    selectedItem={doctor}
                    setSelectedItem={setDoctor}
                    type="Doctor"
                    label=""
                    toDisplay={(item: any) => item.name_code || ""}
                    style={{ width: "100%", paddingRight: "1.4rem" }}
                    fluid={true}
                    icon="search"
                  />
                }
                actionButton={
                  <Button
                    color={
                      complete === "close"
                        ? "red"
                        : props.intraTransferForm?.id
                        ? "yellow"
                        : "green"
                    }
                    style={{ width: "200px" }}
                    loading={!!props.loadingStatus?.[CARD_INTRA_HOSPITAL_TRANSFER]}
                    onClick={handleSave}
                  >
                    {complete ? (
                      <Icon name={complete as any}></Icon>
                    ) : props.intraTransferForm?.id ? (
                      "แก้ไข"
                    ) : (
                      "บันทึก"
                    )}
                  </Button>
                }
                allergyText={
                  <div className="--override-disabled --grey">
                    <TextArea rows={3} disabled={true} style={{ pointerEvents: "auto" }}>
                      {props.intraTransferForm?.allergy || ""}
                    </TextArea>
                  </div>
                }
                languageUX={props.languageUX}
              />
            </>
          )
        }
        onWardPatient={() => {
          props.runSequence({
            sequence: "WardQueue",
            action: "ward",
            admitRoomItemId: props.WardQueueSequence?.selectedAdmitRoomItem?.room_item_id,
            card: CARD_QUEUE_WARD,
          });
        }}
        // Element=
        ErrorMessageWard={
          <ErrorMessage style={{ margin: "1rem 0 0.5rem" }} error={props.errorMessageWard} />
        }
      />

      <Modal open={openModVitalSign} closeOnDimmerClick onClose={() => setOpenModVitalSign(false)}>
        <CardSelectVitalSign
          onEvent={props.onEvent}
          loading={props.selectVitalSignLoading}
          getEncounterVitalSignListErr={props.getEncounterVitalSignListErr}
          encounterVitalSignList={props.encounterVitalSignList}
          onSelect={handleSelectVitalSign}
          languageUX={props.languageUX}
        />
      </Modal>
    </div>
  );
};

export default CardIntraHospitalTransfer;
