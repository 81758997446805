import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  Button,
  Form,
  FormGroup,
  FormField,
  Checkbox,
  Dropdown,
  TextArea
} from 'semantic-ui-react'

const CardNutritionAssessmentUX = (props: any) => {
    return(
      <div
        style={{padding:"15px"}}>
        <div
          style={{padding: "5px", fontSize: "1.2rem", fontWeight: "bold"}}>
          Nutrition Assessment
        </div>
        <Table
          data={props.data}
          headers={`Date,User,A1,A2,A3,A4,A5,${props.isShowPregnancy ? "B1,B2,B3,B4,Delete" : "Delete" }`}
          id="CardNutritionAssessment-Table"
          keys={`date,user_fullname,lose_weight_desc,malnutrition_desc,bmi_outrange_desc,critical_desc,result_desc,${props.isShowPregnancy  ?  "preg1_desc,preg2_desc,preg3_desc,result_preg_desc,delete"  : "delete" }`}
          minRows="3"
          showPagination={false}
          style={{height: "150px"}}>
        </Table>
        <div
          style={{ marginTop: "25px" }}>
          
          <Form>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={7}>
                <div
                  style={{ marginLeft: "30px" }}>
                  A1. ผู้ป่วยมีน้ำหนักตัวลดลงโดยไม่ได้ตั้งใจในช่วง 6 เดือนที่ผ่านมาหรือไม่
                </div>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{  borderRight: "solid 1px rgb(200, 200, 200, 0.5)", marginRight: "20px" }}>
                  
                </div>
                <div>
                  
                  <Checkbox
                    checked={props.lose_weight}
                    id="CardNutritionAssessment-Checkbox-A1"
                    label={props.lose_weight ? "YES" : "NO"}
                    onChange={props.changeNutrition("lose_weight", !props.lose_weight)}
                    toggle={true}>
                  </Checkbox>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={7}>
                <div
                  style={{ marginLeft: "30px" }}>
                  A2. ผู้ป่วยได้รับอาหารน้อยกว่าที่เคยได้ (เกินกว่า 7 วัน)
                </div>
              </FormField>
              <FormField
                inline={true}
                width={6}>
                <div
                  style={{  borderRight: "solid 1px rgb(200, 200, 200, 0.5)", marginRight: "20px" }}>
                  
                </div>
                <div>
                  
                  <Checkbox
                    checked={props.malnutrition}
                    id="CardNutritionAssessment-Checkbox-A2"
                    label={props.malnutrition ? "YES" : "NO"}
                    onChange={props.changeNutrition("malnutrition", !props.malnutrition)}
                    toggle={true}>
                  </Checkbox>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={7}>
                <div
                  style={{ marginLeft: "30px" }}>
                  {"A3. BMI < 18.5 หรือ >= 25.0 Kg/m2"}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={6}>
                <div
                  style={{  borderRight: "solid 1px rgb(200, 200, 200, 0.5)", marginRight: "20px" }}>
                  
                </div>
                <div>
                  
                  <Checkbox
                    checked={props.bmi_outrange}
                    id="CardNutritionAssessment-Checkbox-A3"
                    label={props.bmi_outrange ? "YES" : "NO"}
                    onChange={props.changeNutrition("bmi_outrange", !props.bmi_outrange)}
                    toggle={true}>
                  </Checkbox>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={7}>
                <div
                  style={{ marginLeft: "30px" }}>
                  A4. ผู้ป่วยมีภาวะโรควิกฤตหรือกึ่งวิกฤตร่วมด้วยหรือไม่
                </div>
              </FormField>
              <FormField
                inline={true}
                width={6}>
                <div
                  style={{  borderRight: "solid 1px rgb(200, 200, 200, 0.5)", marginRight: "20px" }}>
                  
                </div>
                <div>
                  
                  <Checkbox
                    checked={props.critical}
                    id="CardNutritionAssessment-Checkbox-A4"
                    label={props.critical ? "YES" : "NO"}
                    onChange={props.changeNutrition("critical", !props.critical)}
                    toggle={true}>
                  </Checkbox>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}>
              <FormField
                inline={true}
                width={7}>
                <div
                  style={{ marginLeft: "30px" }}>
                  {"A5. ผลการคัดกรอง (ถ้าตอบใช่ >= 2 หัวข้อขึ้นไปถือว่าผิดปกติ)"}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={4}>
                <div
                  style={{  borderRight: "solid 1px rgb(200, 200, 200, 0.5)", marginRight: "20px" }}>
                  
                </div>
                <div
                  style={{ width: "100%" }}>
                  
                  <Dropdown
                    fluid={true}
                    id="CardNutritionAssessment-Dropdown-A5"
                    onChange={props.changeNutrition("result")}
                    options={props.resultOptions}
                    selection={true}
                    value={props.result}>
                  </Dropdown>
                </div>
              </FormField>
              <FormField
                inline={true}
                style={{ ...(props.result !== "OTH" && { display: "none" }) }}
                width={5}>
                <div>
                  ระบุ
                </div>
                <div
                  style={{ width: "100%" }}>
                  
                  <TextArea
                    id="CardNutritionAssessment-TextArea-A5Specific"
                    onChange={props.changeNutrition("resultRemark")}
                    rows="1"
                    value={props.resultRemark}>
                  </TextArea>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ ...(!props.isShowPregnancy && {display: "none"})  }}>
              <FormField
                inline={true}
                width={7}>
                <div
                  style={{ marginLeft: "30px" }}>
                  Nutrition Assessment (ใช้เฉพาะหญิงตั้งครรภ์)
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ ...(!props.isShowPregnancy && {display: "none"})  }}>
              <FormField
                inline={true}
                width={7}>
                <div
                  style={{ marginLeft: "30px" }}>
                  {"B1. ไตรมาสแรก (อายุครรภ์ 0-12 สัปดาห์) น้ำหนักลด > 0.5 kg/week ร่วมกับอาเจียน,กินไม่ได้"}
                </div>
              </FormField>
              <FormField
                inline={true}
                width={6}>
                <div
                  style={{  borderRight: "solid 1px rgb(200, 200, 200, 0.5)", marginRight: "20px" }}>
                  
                </div>
                <div>
                  
                  <Checkbox
                    checked={props.preg1}
                    label={props.preg1 ? "YES" : "NO"}
                    onChange={props.changeNutrition("preg1", !props.preg1)}
                    toggle={true}>
                  </Checkbox>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ ...(!props.isShowPregnancy && {display: "none"})  }}>
              <FormField
                inline={true}
                width={7}>
                <div
                  style={{ marginLeft: "30px" }}>
                  B2. ไตรมาสที่ 2 ขึ้นไป (อายุครรภ์ 12 สัปดาห์ขึ้นไป) น้ำหนักเพิ่ม/ลด 1 kg/week
                </div>
              </FormField>
              <FormField
                inline={true}
                width={6}>
                <div
                  style={{  borderRight: "solid 1px rgb(200, 200, 200, 0.5)", marginRight: "20px" }}>
                  
                </div>
                <div>
                  
                  <Checkbox
                    checked={props.preg2}
                    label={props.preg2 ? "YES" : "NO"}
                    onChange={props.changeNutrition("preg2", !props.preg2)}
                    toggle={true}>
                  </Checkbox>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ ...(!props.isShowPregnancy && {display: "none"})  }}>
              <FormField
                inline={true}
                width={7}>
                <div
                  style={{ marginLeft: "30px" }}>
                  B3. ไตรมาสที่ 2 ขึ้นไป (อายุครรภ์ 12 สัปดาห์ขึ้นไป) น้ำหนักร่มกับอาการบวม/ความดันโลหิตสูง
                </div>
              </FormField>
              <FormField
                inline={true}
                width={6}>
                <div
                  style={{  borderRight: "solid 1px rgb(200, 200, 200, 0.5)", marginRight: "20px" }}>
                  
                </div>
                <div>
                  
                  <Checkbox
                    checked={props.preg3}
                    label={props.preg3 ? "YES" : "NO"}
                    onChange={ props.changeNutrition("preg3", !props.preg3)}
                    toggle={true}>
                  </Checkbox>
                </div>
              </FormField>
            </FormGroup>
            <FormGroup
              inline={true}
              style={{ ...(!props.isShowPregnancy && {display: "none"})  }}>
              <FormField
                inline={true}
                width={7}>
                <div
                  style={{ marginLeft: "30px" }}>
                  B4. ผลการคัดกรอง (ใช้เฉพาะหญิงตั้งครรภ์)
                </div>
              </FormField>
              <FormField
                inline={true}
                width={6}>
                <div
                  style={{  borderRight: "solid 1px rgb(200, 200, 200, 0.5)", marginRight: "20px" }}>
                  
                </div>
                <div
                  style={{ width: "55%" }}>
                  
                  <Dropdown
                    fluid={true}
                    onChange={props.changeNutrition("result_preg")}
                    options={props.resultPregOptions}
                    selection={true}
                    value={props.result_preg}>
                  </Dropdown>
                </div>
              </FormField>
            </FormGroup>
          </Form>
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", margin: "0px 20px 40px" }}>
          
          <Button
            color="blue"
            id="CardNutritionAssessment-Button-Reassessment"
            onClick={props.onReassess}>
            Reassessment
          </Button>
        </div>
      </div>
    )
}


export default CardNutritionAssessmentUX

export const screenPropsDefault = {}

/* Date Time : Thu Jan 23 2025 17:44:05 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "style": {
          "type": "code",
          "value": "{padding:\"15px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 46,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "Nutrition Assessment"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"5px\", fontSize: \"1.2rem\", fontWeight: \"bold\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 47,
      "name": "Table",
      "parent": 0,
      "props": {
        "data": {
          "type": "code",
          "value": "props.data"
        },
        "headers": {
          "type": "code",
          "value": "`Date,User,A1,A2,A3,A4,A5,${props.isShowPregnancy ? \"B1,B2,B3,B4,Delete\" : \"Delete\" }`"
        },
        "id": {
          "type": "value",
          "value": "CardNutritionAssessment-Table"
        },
        "keys": {
          "type": "code",
          "value": "`date,user_fullname,lose_weight_desc,malnutrition_desc,bmi_outrange_desc,critical_desc,result_desc,${props.isShowPregnancy  ?  \"preg1_desc,preg2_desc,preg3_desc,result_preg_desc,delete\"  : \"delete\" }`"
        },
        "minRows": {
          "type": "value",
          "value": "3"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"150px\"}"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": null,
      "id": 48,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\", margin: \"0px 20px 40px\" }"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "Button",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "Reassessment"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "id": {
          "type": "value",
          "value": "CardNutritionAssessment-Button-Reassessment"
        },
        "onClick": {
          "type": "code",
          "value": "props.onReassess"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": null,
      "id": 50,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ marginTop: \"25px\" }"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "Form",
      "parent": 50,
      "props": {
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 52,
      "name": "FormGroup",
      "parent": 51,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 53,
      "name": "FormField",
      "parent": 52,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 53,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 54,
      "name": "FormField",
      "parent": 52,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 55,
      "name": "div",
      "parent": 53,
      "props": {
        "children": {
          "type": "value",
          "value": "A1. ผู้ป่วยมีน้ำหนักตัวลดลงโดยไม่ได้ตั้งใจในช่วง 6 เดือนที่ผ่านมาหรือไม่"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"30px\" }"
        }
      },
      "seq": 55,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  borderRight: \"solid 1px rgb(200, 200, 200, 0.5)\", marginRight: \"20px\" }"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 54,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 58,
      "name": "Checkbox",
      "parent": 57,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.lose_weight"
        },
        "id": {
          "type": "value",
          "value": "CardNutritionAssessment-Checkbox-A1"
        },
        "label": {
          "type": "code",
          "value": "props.lose_weight ? \"YES\" : \"NO\""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNutrition(\"lose_weight\", !props.lose_weight)"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 58,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 59,
      "name": "FormGroup",
      "parent": 51,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "FormGroup",
      "parent": 51,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormGroup",
      "parent": 51,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormGroup",
      "parent": 51,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormGroup",
      "parent": 51,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(!props.isShowPregnancy && {display: \"none\"})  }"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormGroup",
      "parent": 51,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(!props.isShowPregnancy && {display: \"none\"})  }"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormGroup",
      "parent": 51,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(!props.isShowPregnancy && {display: \"none\"})  }"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormGroup",
      "parent": 51,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(!props.isShowPregnancy && {display: \"none\"})  }"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 59,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 59,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 60,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 60,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 71,
      "name": "FormField",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "FormField",
      "parent": 61,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 73,
      "name": "FormField",
      "parent": 62,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 74,
      "name": "FormField",
      "parent": 62,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 75,
      "name": "FormField",
      "parent": 63,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 76,
      "name": "FormField",
      "parent": 64,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 77,
      "name": "FormField",
      "parent": 64,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 77,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 79,
      "name": "FormField",
      "parent": 65,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 80,
      "name": "FormField",
      "parent": 66,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 80,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 81,
      "name": "FormField",
      "parent": 66,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "FormGroup",
      "parent": 51,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(!props.isShowPregnancy && {display: \"none\"})  }"
        },
        "widths": {
          "type": "code",
          "value": ""
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormField",
      "parent": 82,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "7"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "FormField",
      "parent": 82,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "6"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": null,
      "id": 85,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "value",
          "value": "A2. ผู้ป่วยได้รับอาหารน้อยกว่าที่เคยได้ (เกินกว่า 7 วัน)"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"30px\" }"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  borderRight: \"solid 1px rgb(200, 200, 200, 0.5)\", marginRight: \"20px\" }"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": null,
      "id": 88,
      "name": "div",
      "parent": 69,
      "props": {
        "children": {
          "type": "code",
          "value": "\"A3. BMI < 18.5 หรือ >= 25.0 Kg/m2\""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"30px\" }"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 89,
      "name": "div",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  borderRight: \"solid 1px rgb(200, 200, 200, 0.5)\", marginRight: \"20px\" }"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": null,
      "id": 90,
      "name": "div",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "div",
      "parent": 71,
      "props": {
        "children": {
          "type": "value",
          "value": "A4. ผู้ป่วยมีภาวะโรควิกฤตหรือกึ่งวิกฤตร่วมด้วยหรือไม่"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"30px\" }"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": null,
      "id": 92,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  borderRight: \"solid 1px rgb(200, 200, 200, 0.5)\", marginRight: \"20px\" }"
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 72,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 73,
      "props": {
        "children": {
          "type": "code",
          "value": "\"A5. ผลการคัดกรอง (ถ้าตอบใช่ >= 2 หัวข้อขึ้นไปถือว่าผิดปกติ)\""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"30px\" }"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 74,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  borderRight: \"solid 1px rgb(200, 200, 200, 0.5)\", marginRight: \"20px\" }"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 74,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": null,
      "id": 97,
      "name": "div",
      "parent": 75,
      "props": {
        "children": {
          "type": "value",
          "value": "Nutrition Assessment (ใช้เฉพาะหญิงตั้งครรภ์)"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"30px\" }"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": null,
      "id": 98,
      "name": "div",
      "parent": 76,
      "props": {
        "children": {
          "type": "code",
          "value": "\"B1. ไตรมาสแรก (อายุครรภ์ 0-12 สัปดาห์) น้ำหนักลด > 0.5 kg/week ร่วมกับอาเจียน,กินไม่ได้\""
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"30px\" }"
        }
      },
      "seq": 98,
      "void": false
    },
    {
      "from": null,
      "id": 99,
      "name": "div",
      "parent": 77,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  borderRight: \"solid 1px rgb(200, 200, 200, 0.5)\", marginRight: \"20px\" }"
        }
      },
      "seq": 99,
      "void": false
    },
    {
      "from": null,
      "id": 100,
      "name": "div",
      "parent": 77,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 100,
      "void": false
    },
    {
      "from": null,
      "id": 101,
      "name": "div",
      "parent": 78,
      "props": {
        "children": {
          "type": "value",
          "value": "B2. ไตรมาสที่ 2 ขึ้นไป (อายุครรภ์ 12 สัปดาห์ขึ้นไป) น้ำหนักเพิ่ม/ลด 1 kg/week"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"30px\" }"
        }
      },
      "seq": 101,
      "void": false
    },
    {
      "from": null,
      "id": 102,
      "name": "div",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  borderRight: \"solid 1px rgb(200, 200, 200, 0.5)\", marginRight: \"20px\" }"
        }
      },
      "seq": 102,
      "void": false
    },
    {
      "from": null,
      "id": 103,
      "name": "div",
      "parent": 79,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 103,
      "void": false
    },
    {
      "from": null,
      "id": 104,
      "name": "div",
      "parent": 80,
      "props": {
        "children": {
          "type": "value",
          "value": "B3. ไตรมาสที่ 2 ขึ้นไป (อายุครรภ์ 12 สัปดาห์ขึ้นไป) น้ำหนักร่มกับอาการบวม/ความดันโลหิตสูง"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"30px\" }"
        }
      },
      "seq": 104,
      "void": false
    },
    {
      "from": null,
      "id": 105,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  borderRight: \"solid 1px rgb(200, 200, 200, 0.5)\", marginRight: \"20px\" }"
        }
      },
      "seq": 105,
      "void": false
    },
    {
      "from": null,
      "id": 106,
      "name": "div",
      "parent": 81,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 106,
      "void": false
    },
    {
      "from": null,
      "id": 107,
      "name": "div",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "B4. ผลการคัดกรอง (ใช้เฉพาะหญิงตั้งครรภ์)"
        },
        "style": {
          "type": "code",
          "value": "{ marginLeft: \"30px\" }"
        }
      },
      "seq": 107,
      "void": false
    },
    {
      "from": null,
      "id": 108,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{  borderRight: \"solid 1px rgb(200, 200, 200, 0.5)\", marginRight: \"20px\" }"
        }
      },
      "seq": 108,
      "void": false
    },
    {
      "from": null,
      "id": 109,
      "name": "div",
      "parent": 84,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"55%\" }"
        }
      },
      "seq": 109,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 110,
      "name": "Checkbox",
      "parent": 87,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.malnutrition"
        },
        "id": {
          "type": "value",
          "value": "CardNutritionAssessment-Checkbox-A2"
        },
        "label": {
          "type": "code",
          "value": "props.malnutrition ? \"YES\" : \"NO\""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNutrition(\"malnutrition\", !props.malnutrition)"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 110,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 111,
      "name": "Checkbox",
      "parent": 90,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.bmi_outrange"
        },
        "id": {
          "type": "value",
          "value": "CardNutritionAssessment-Checkbox-A3"
        },
        "label": {
          "type": "code",
          "value": "props.bmi_outrange ? \"YES\" : \"NO\""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNutrition(\"bmi_outrange\", !props.bmi_outrange)"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 111,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 112,
      "name": "Checkbox",
      "parent": 93,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.critical"
        },
        "id": {
          "type": "value",
          "value": "CardNutritionAssessment-Checkbox-A4"
        },
        "label": {
          "type": "code",
          "value": "props.critical ? \"YES\" : \"NO\""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNutrition(\"critical\", !props.critical)"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 112,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 113,
      "name": "Checkbox",
      "parent": 100,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.preg1"
        },
        "label": {
          "type": "code",
          "value": "props.preg1 ? \"YES\" : \"NO\""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNutrition(\"preg1\", !props.preg1)"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 113,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 114,
      "name": "Checkbox",
      "parent": 103,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.preg2"
        },
        "label": {
          "type": "code",
          "value": "props.preg2 ? \"YES\" : \"NO\""
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNutrition(\"preg2\", !props.preg2)"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 114,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 115,
      "name": "Checkbox",
      "parent": 106,
      "props": {
        "checked": {
          "type": "code",
          "value": "props.preg3"
        },
        "label": {
          "type": "code",
          "value": "props.preg3 ? \"YES\" : \"NO\""
        },
        "onChange": {
          "type": "code",
          "value": " props.changeNutrition(\"preg3\", !props.preg3)"
        },
        "toggle": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 115,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 116,
      "name": "Dropdown",
      "parent": 109,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNutrition(\"result_preg\")"
        },
        "options": {
          "type": "code",
          "value": "props.resultPregOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.result_preg"
        }
      },
      "seq": 116,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 117,
      "name": "Dropdown",
      "parent": 96,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardNutritionAssessment-Dropdown-A5"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNutrition(\"result\")"
        },
        "options": {
          "type": "code",
          "value": "props.resultOptions"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.result"
        }
      },
      "seq": 117,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 118,
      "name": "FormField",
      "parent": 62,
      "props": {
        "inline": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.result !== \"OTH\" && { display: \"none\" }) }"
        },
        "width": {
          "type": "code",
          "value": "5"
        }
      },
      "seq": 118,
      "void": false
    },
    {
      "from": null,
      "id": 119,
      "name": "div",
      "parent": 118,
      "props": {
        "children": {
          "type": "value",
          "value": "ระบุ"
        }
      },
      "seq": 119,
      "void": false
    },
    {
      "from": null,
      "id": 120,
      "name": "div",
      "parent": 118,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\" }"
        }
      },
      "seq": 120,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 121,
      "name": "TextArea",
      "parent": 120,
      "props": {
        "id": {
          "type": "value",
          "value": "CardNutritionAssessment-TextArea-A5Specific"
        },
        "onChange": {
          "type": "code",
          "value": "props.changeNutrition(\"resultRemark\")"
        },
        "rows": {
          "type": "value",
          "value": "1"
        },
        "value": {
          "type": "code",
          "value": "props.resultRemark"
        }
      },
      "seq": 121,
      "void": true
    }
  ],
  "forwardRef": false,
  "height": 90,
  "isMounted": false,
  "memo": false,
  "name": "CardNutritionAssessmentUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 80
}

*********************************************************************************** */
