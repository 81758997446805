import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Input,
  Radio,
} from "semantic-ui-react";
import ModSaveAssessment from "./ModSaveAssessment";
import {
  CardFallRiskAssessment,
  CardPainAssessment,
} from "react-lib/apps/HISV3/PTM/CardAssessmentBundle";

import { EDUCATION, EDUCATION_EVALUATION, EDUCATION_WAY } from "./sequence/Assessment";
import { useIntl } from "react-intl";

const CardDischargeReassessment = (props: any) => {
  const intl = useIntl();
  const [openSave, setOpenSave] = useState(false);

  useEffect(() => {
    if (!Number.isInteger(props?.selectedEncounter?.patient_id)) return;
    props.runSequence({ sequence: "Assessment", restart: true });
  }, []);

  const reassess = (form: string, data: any) => () => {
    console.log(props.DJANGO.user);
    const newData = [
      ...(props.AssessmentSequence?.[form]?.data || []),
      {
        ...data,
        edit_user: props.DJANGO.user.id,
        user_fullname: props.DJANGO.user.full_name,
        date: moment().format("HH:mm DD/MM/YYYY"),
      },
    ];
    props.setProp(`AssessmentSequence.${form}.data`, newData);
  };

  const patientEducationOptions = (
    props.masterOptions?.patientEducation || []
  ).map((item: any) => ({
    ...item,
    key: item?.key.toString(),
    value: item?.value.toString(),
  }));

  const handleSave = (params: any) => {
    props.runSequence(params);
    setOpenSave(false);
  };

  // Reassessment Assessment
  const reassessmentAssessment =
    props.AssessmentSequence?.reassessmentAssessment?.data || {};

  const changeForm = (_event: any, data: any) => {
    // reset detail
    if (
      (["barriers", "follow_up"].includes(data.name) && !data.checked) ||
      (data.name === "readiness" && data.checked) ||
      (data.name === "education_method" && !["3909"].includes(data.value))
    ) {
      props.setProp(
        `AssessmentSequence.reassessmentAssessment.data.${data.name}_detail`,
        ""
      );
    }

    if (data.type === "checkbox" || data.type === "radio") {
      props.setProp(
        `AssessmentSequence.reassessmentAssessment.data.${data.name}`,
        data.checked
      );
    } else {
      props.setProp(
        `AssessmentSequence.reassessmentAssessment.data.${data.name}`,
        data.value
      );
    }
  };

  return (
    <>
      <div
        style={{
          margin: `0px 15px`,
          display: `flex`,
          justifyContent: `flex-end`,
        }}
      >
        <Button
          id="CardDischargeReassessment-Button-Save"
          color="green"
          onClick={() => {
            setOpenSave(true);
          }}
        >
          Save
        </Button>
      </div>

      <ModSaveAssessment
        // data
        open={openSave}
        // config
        isSaveReassessment={true}
        // callback
        onSave={handleSave}
        onClose={() => setOpenSave(false)}
        languageUX={props.languageUX}
      />
      <div style={{ margin: "15px" }}>
        <div 
          id="CardDischargeReassessment-Div-Title"
          style={{ fontSize: "1.3rem", fontWeight: "bold" }}
        >
          ประเมินผู้ป่วยหลังออกจากห้องตรวจ (OPD Reassessment)
        </div>
        <Form>
          <Form.Field inline={true}>
            <div style={{ display: "flex", margin: "10px 0px" }}>
              <label style={{ marginRight: "13px" }}>
                Patient and Family Education Need
              </label>
              <Radio
                id="CardDischargeReassessment-Radio-EducationNeed"
                toggle
                onChange={changeForm}
                checked={reassessmentAssessment?.education_need || false}
                name="education_need"
              />
            </div>
          </Form.Field>
          <Divider />

          {reassessmentAssessment?.education_need && (
            <Form>
              <Form.Group inline>
                <Form.Field
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  width={4}
                >
                  <label>{intl.formatMessage({ id: "ผู้บันทึก/แก้ไข" })}</label>
                </Form.Field>
                <Form.Field width={12}>
                  <Button 
                    disabled
                    id="CardDischargeReassessment-Button-Editor"
                  >
                    {props.DJANGO?.user?.full_name}
                  </Button>
                </Form.Field>
              </Form.Group>

              <Form.Group inline>
                <Form.Field
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  width={4}
                >
                  <label>{intl.formatMessage({ id: "ข้อจำกัดในการรับรู้" })}</label>
                </Form.Field>
                <Form.Field width={5}>
                  <Radio
                    id="CardDischargeReassessment-Radio-Barriers"
                    toggle
                    onChange={changeForm}
                    checked={reassessmentAssessment?.barriers || false}
                    name="barriers"
                  />
                  <label>
                    {reassessmentAssessment?.barriers ? "Yes" : "No"}
                  </label>
                </Form.Field>
                <Form.Field
                  id="CardDischargeReassessment-Input-Barriers-Detail"
                  control={Input}
                  disabled={!reassessmentAssessment?.barriers}
                  label={intl.formatMessage({ id: "ระบุ" })}
                  onChange={changeForm}
                  value={reassessmentAssessment?.barriers_detail}
                  name="barriers_detail"
                  width={7}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Field
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  width={4}
                >
                  <label>Readiness and Willingness</label>
                </Form.Field>
                <Form.Field inline={true} width={5}>
                  <Radio
                    id="CardDischargeReassessment-Radio-Readiness"
                    toggle
                    onChange={changeForm}
                    value={reassessmentAssessment?.barriers_detail}
                    checked={
                      reassessmentAssessment?.readiness === undefined
                        ? false
                        : reassessmentAssessment?.readiness
                    }
                    name="readiness"
                  />
                  <label>
                    {reassessmentAssessment?.readiness ? "Yes" : "No"}
                  </label>
                </Form.Field>
                <Form.Field
                  id="CardDischargeReassessment-Input-Readiness-Detail"
                  control={Input}
                  disabled={reassessmentAssessment?.readiness}
                  label={intl.formatMessage({ id: "ระบุ" })}
                  onChange={changeForm}
                  value={reassessmentAssessment?.readiness_detail}
                  name="readiness_detail"
                  width={7}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Field
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  width={4}
                >
                  <label>{intl.formatMessage({ id: "ผู้รับคำแนะนำ" })}</label>
                </Form.Field>
                <Form.Field
                  id="CardDischargeReassessment-Dropdown-EducationWay"
                  control={Dropdown}
                  fluid
                  multiple
                  selection
                  width={12}
                  options={EDUCATION_WAY}
                  onChange={changeForm}
                  value={reassessmentAssessment?.education_way}
                  name="education_way"
                  style={{ width: `100%` }}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Field
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  width={4}
                >
                  <label>{intl.formatMessage({ id: "วิธีการให้ความรู้" })}</label>
                </Form.Field>
                <Form.Field
                  id="CardDischargeReassessment-Dropdown-EducationMethod"
                  control={Dropdown}
                  fluid
                  multiple
                  selection
                  width={12}
                  options={patientEducationOptions}
                  onChange={changeForm}
                  value={reassessmentAssessment?.education_method}
                  name="education_method"
                  style={{ width: `100%` }}
                />
              </Form.Group>

              {(reassessmentAssessment?.education_method || []).includes(
                "3909"
              ) && (
                <Form.Group inline>
                  <Form.Field
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    width={4}
                  />
                  <Form.Field
                    id="CardDischargeReassessment-Input-EducationWay-Detail"
                    control={Input}
                    label={intl.formatMessage({ id: "ระบุ" })}
                    width={12}
                    onChange={changeForm}
                    value={reassessmentAssessment?.education_method_detail}
                    name="education_method_detail"
                  />
                </Form.Group>
              )}

              <Form.Group inline>
                <Form.Field
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  width={4}
                >
                  <label>{intl.formatMessage({ id: "คำแนะนำที่ให้" })}</label>
                </Form.Field>
                <Form.Field
                  id="CardDischargeReassessment-Dropdown-Education"
                  control={Dropdown}
                  fluid
                  multiple
                  selection
                  width={12}
                  options={EDUCATION}
                  onChange={changeForm}
                  value={reassessmentAssessment?.education}
                  name="education"
                  style={{ width: `100%` }}
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Field
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  width={4}
                >
                  <label>Education Evaluation</label>
                </Form.Field>
                <Form.Field
                  id="CardDischargeReassessment-Dropdown-EducationEvaluation"
                  control={Dropdown}
                  fluid
                  selection
                  width={5}
                  options={EDUCATION_EVALUATION}
                  onChange={changeForm}
                  value={reassessmentAssessment?.education_evaluation}
                  name="education_evaluation"
                  style={{ width: `100%` }}
                />
                <Form.Field
                  id="CardDischargeReassessment-Input-EducationEvaluation-Detail"
                  control={Input}
                  label={intl.formatMessage({ id: "ระบุ" })}
                  width={7}
                  onChange={changeForm}
                  value={reassessmentAssessment?.education_evaluation_detail}
                  name="education_evaluation_detail"
                />
              </Form.Group>

              <Form.Group inline>
                <Form.Field
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  width={4}
                >
                  <label>Follow up</label>
                </Form.Field>
                <Form.Field inline={true} width={5}>
                  <Radio
                    id="CardDischargeReassessment-Radio-FollowUp"
                    toggle
                    onChange={changeForm}
                    checked={reassessmentAssessment?.follow_up || false}
                    name="follow_up"
                  />
                  <label>
                    {reassessmentAssessment?.follow_up ? "Yes" : "No"}
                  </label>
                </Form.Field>
                <Form.Field
                  id="CardDischargeReassessment-Input-FollowUp-Detail"
                  control={Input}
                  disabled={!reassessmentAssessment?.follow_up}
                  onChange={changeForm}
                  value={reassessmentAssessment?.follow_up_detail}
                  name="follow_up_detail"
                  label={intl.formatMessage({ id: "ระบุ" })}
                  width={7}
                />
              </Form.Group>
            </Form>
          )}
        </Form>
      </div>

      <hr />
      <CardFallRiskAssessment
        AssessmentSequence={props.AssessmentSequence}
        reassess={reassess}
        languageUX={props.languageUX}
      />
      <hr />
      <CardPainAssessment
        AssessmentSequence={props.AssessmentSequence}
        masterOptions={props.masterOptions}
        reassess={reassess}
        languageUX={props.languageUX}
      />
    </>
  );
};

export default CardDischargeReassessment;
