import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Button
} from 'semantic-ui-react'
import {
  Table
} from 'react-lib/frameworks/Table'
import {
  DateTextBox
} from 'react-lib/apps/common'

const CardNurseNoteUX = (props: any) => {

    const isMounted = React.useRef(true);
    const intl = useIntl();

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);


    return(
      <div
        id="CardNurseNoteUX-div-0"
        style={{ width: "100%", height: "100%", padding: "5px" }}>
        <div
          id="CardNurseNoteUX-div-1"
          style={{ display: "flex", justifyContent: "flex-end", marginBottom: "10px" }}>
          <DateTextBox
            id="CardNurseNoteUX-DateTextBox-11"
            onChange={props.onSetStartDate}
            placeholder="วันที่เริ่ม"
            style={{paddingRight: "10px"}}
            value={props.selectedStartDate}>
          </DateTextBox>
          <DateTextBox
            id="CardNurseNoteUX-DateTextBox-12"
            onChange={props.onSetEndDate}
            placeholder="วันที่จบ"
            style={{paddingRight: "10px"}}
            value={props.selectedEndDate}>
          </DateTextBox>
          <Button
            color="blue"
            id="CardNurseNoteUX-Button-7"
            loading={props.nurseNoteLoading}
            onClick={props.nurseNoteSearch}
            style={{ ...(props.isNurseForm && { width: "150px" }) }}>
            ค้นหา
          </Button>
          <Button
            color="blue"
            id="CardNurseNoteUX-Button-3"
            onClick={props.openModalNote}
            style={{ ...(props.isNurseForm && { display: "none" }) }}>
            เพิ่ม
          </Button>
          <Button
            className="icon"
            color="blue"
            disabled={props.showPrint}
            icon="print"
            id="CardNurseNoteUX-Button-13"
            loading={props.loadingPrint || false}
            onClick={props.handleNurseNoteReport}
            style={{ ...(props.isNurseForm && { display: "none" }) }}>
          </Button>
        </div>
        <div
          id="CardNurseNoteUX-div-2">
          <Table
            columns={props.columnsNurseNote}
            data={props.nurseNoteList}
            getTrProps={props.onSelectedRow}
            id="CardNurseNoteUX-Table-6"
            minRows="15"
            showPagination={false}
            style={{ ...(props.isNurseForm && { height: "500px" }) }}>
          </Table>
        </div>
        <div
          id="CardNurseNoteUX-div-14"
          style={{ display: "flex", justifyContent: "flex-end", marginTop: "15px", ...(!props.isNurseForm && { display: "none" } ) }}>
          
          <Button
            color="blue"
            disabled={props.showPrint}
            id="CardNurseNoteUX-Button-15"
            loading={props.loadingPrint || false}
            onClick={props.handleNurseNoteReport}
            style={{ width: "150px" }}>
            พิมพ์
          </Button>
        </div>
      </div>
    )
}


export default CardNurseNoteUX

export const screenPropsDefault = {}

/* Date Time : Thu Mar 20 2025 14:32:22 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardNurseNoteUX-div-0"
        },
        "style": {
          "type": "code",
          "value": "{ width: \"100%\", height: \"100%\", padding: \"5px\" }"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "id": {
          "type": "value",
          "value": "CardNurseNoteUX-div-1"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\", marginBottom: \"10px\" }"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "id": {
          "type": "value",
          "value": "CardNurseNoteUX-div-2"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "เพิ่ม"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "id": {
          "type": "value",
          "value": "CardNurseNoteUX-Button-3"
        },
        "onClick": {
          "type": "code",
          "value": "props.openModalNote"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.isNurseForm && { display: \"none\" }) }"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 6,
      "name": "Table",
      "parent": 2,
      "props": {
        "columns": {
          "type": "code",
          "value": "props.columnsNurseNote"
        },
        "data": {
          "type": "code",
          "value": "props.nurseNoteList"
        },
        "defaultPageSize": {
          "type": "value",
          "value": ""
        },
        "getTrProps": {
          "type": "code",
          "value": "props.onSelectedRow"
        },
        "headers": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardNurseNoteUX-Table-6"
        },
        "keys": {
          "type": "value",
          "value": ""
        },
        "minRows": {
          "type": "value",
          "value": "15"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.isNurseForm && { height: \"500px\" }) }"
        },
        "widths": {
          "type": "value",
          "value": ""
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "Button",
      "parent": 1,
      "props": {
        "children": {
          "type": "value",
          "value": "ค้นหา"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "id": {
          "type": "value",
          "value": "CardNurseNoteUX-Button-7"
        },
        "loading": {
          "type": "code",
          "value": "props.nurseNoteLoading"
        },
        "onClick": {
          "type": "code",
          "value": "props.nurseNoteSearch"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.isNurseForm && { width: \"150px\" }) }"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 11,
      "name": "DateTextBox",
      "parent": 1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardNurseNoteUX-DateTextBox-11"
        },
        "onChange": {
          "type": "code",
          "value": "props.onSetStartDate"
        },
        "placeholder": {
          "type": "value",
          "value": "วันที่เริ่ม"
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: \"10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedStartDate"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 12,
      "name": "DateTextBox",
      "parent": 1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardNurseNoteUX-DateTextBox-12"
        },
        "onChange": {
          "type": "code",
          "value": "props.onSetEndDate"
        },
        "placeholder": {
          "type": "value",
          "value": "วันที่จบ"
        },
        "style": {
          "type": "code",
          "value": "{paddingRight: \"10px\"}"
        },
        "value": {
          "type": "code",
          "value": "props.selectedEndDate"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 13,
      "name": "Button",
      "parent": 1,
      "props": {
        "className": {
          "type": "value",
          "value": "icon"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.showPrint"
        },
        "icon": {
          "type": "value",
          "value": "print"
        },
        "id": {
          "type": "value",
          "value": "CardNurseNoteUX-Button-13"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingPrint || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleNurseNoteReport"
        },
        "style": {
          "type": "code",
          "value": "{ ...(props.isNurseForm && { display: \"none\" }) }"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": null,
      "id": 14,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardNurseNoteUX-div-14"
        },
        "style": {
          "type": "code",
          "value": "{ display: \"flex\", justifyContent: \"flex-end\", marginTop: \"15px\", ...(!props.isNurseForm && { display: \"none\" } ) }"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "Button",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "พิมพ์"
        },
        "color": {
          "type": "value",
          "value": "blue"
        },
        "disabled": {
          "type": "code",
          "value": "props.showPrint"
        },
        "id": {
          "type": "value",
          "value": "CardNurseNoteUX-Button-15"
        },
        "loading": {
          "type": "code",
          "value": "props.loadingPrint || false"
        },
        "onClick": {
          "type": "code",
          "value": "props.handleNurseNoteReport"
        },
        "size": {
          "type": "value",
          "value": ""
        },
        "style": {
          "type": "code",
          "value": "{ width: \"150px\" }"
        }
      },
      "seq": 15,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": true,
  "memo": false,
  "name": "CardNurseNoteUX",
  "project": "IsHealth",
  "screenPropsDefault": {
  },
  "width": 75
}

*********************************************************************************** */
